import setup from './setup';
import application from './application';
import forms from './forms';
import elements from './elements';
import pages from './pages';
import utilities from './utilities';
import support from './support';
import other from './other';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [
        setup,  // opciones fijas para todos los negocios excepto holdings (Filtrar holdings por URL y desaparecer esto)
        // application,
        //  forms, 
        //  elements, 
        //  pages, 
        //  utilities, 
        //  support,
        //  other
        ],
};

export default menuItems;
// 7,9,18,3,12 = 49  -->69
