import PropTypes from "prop-types";
import { createContext, useEffect, useReducer, useState } from "react";
// import Axios from 'utils/axios';
// import axios from 'utils/axios';
import axios from "axios";
// third-party
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import * as amplitude from "@amplitude/analytics-browser";

// action - state management
import { LOGIN, LOGOUT } from "store/actions";
import accountReducer from "store/accountReducer";

// project imports
import Loader from "ui-component/Loader";
import { FIREBASE_API } from "config";
import { API_URL, API_KEY } from "config";
import { isNull } from "lodash";

// firebase initialize
if (!firebase.apps.length) {
  firebase.initializeApp(FIREBASE_API);
}

// const
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

// const [countryCode, setCountryCode] = useState("sv");

const fetchCountryCode = async () => {
  try {
    // Obtener la dirección IP del usuario mediante el servicio externo ipify
    const ipResponse = await axios.get("https://api.ipify.org?format=json");
    // const userIP = ipResponse.data.ip;
    navigator.geolocation.getCurrentPosition(async (position) => {
      const { latitude, longitude } = position.coords;
      // console.log(latitude, longitude);
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${API_KEY}`
      );
      const results = response.data.results;
      // console.log(results);
      if (results.length > 0) {
        // La respuesta puede contener múltiples resultados; selecciona el primer resultado para obtener la información del país
        const countryData = results[0].address_components.find((component) =>
          component.types.includes("country")
        );
        const countryCode = countryData.short_name;
        // console.log(countryCode);
        localStorage.setItem("ccd0", countryCode.toLowerCase());
        localStorage.setItem("stlt", latitude);
        localStorage.setItem("stln", longitude);
      } else {
        console.error("No se encontraron resultados.");
      }
    });
  } catch (error) {
    console.error("Error fetching country code:", error);
  }
};

const getUserAccountData = (user, opType, uid) => {
  let url = "";
  // console.log("GETTING PRIVATE TOKEN FOR");
  if (user.email) {
    url = API_URL + "users/signin";
  } else {
    url = API_URL + "users/signin";
  }
  // console.log("----->",url);
  const data = {
    optype: opType,
    uid: uid,
  };
  // console.log("payload", data);

  return new Promise((resolve, reject) => {
    axios
      .post(url, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // console.log(response);
        // console.log(response.data.auth_token);
        // resolve(response.data.businessData);
        resolve(response.data);
      })
      .catch((error) => {
        // console.error(error);
        // window.location.replace("/login");
        console.error("FAIL TO SET PRIVATE TOKEN");
        reject(false);
      });
  });
};

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext(null);
export const FirebaseProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);

  useEffect(() => {
    const unsubscribe = firebase.auth().onIdTokenChanged(async (user) => {
      if (!user) {
        // Si el usuario no está autenticado, lo redirigimos a la página de inicio de sesión
        // console.log("IS NOT AUTH");
        setTimeout(function () {
          if (
            window.location.pathname !== "/" &&
            window.location.pathname !== "/login"
          ) {
            console.log("IS NOT AUTH --- REDIRECTING");
            window.location.replace("/login");
          }
        }, 1000);
      } else {
        // console.log(user)
        // console.log("Is auth!");
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          var response = null;
          // console.log(firebase.auth().currentUser.auth);
          var last_bu = null;
          var lastbudatav = null;
          var businessData = [];
          var idToken = "";
          try {
            idToken = await firebase
              .auth()
              .currentUser.getIdToken(/* forceRefresh */ true);
            var opType = "";
            var uid = idToken;
            var signup = user.metadata.creationTime;
            var signin = user.metadata.lastSignInTime;
            if (signin === signup) {
              opType = "signUp";
              amplitude.track("Sign Up");
            } else {
              opType = "signIn";
              amplitude.track("Sign In");
            }
            try {
              response = await getUserAccountData(user, opType, uid);

              businessData = response.businessData;

              last_bu = response.last_bu;
            } catch (error) {
              // window.location.replace("/login");
              console.log("Fail to read private token");
            }
            try {
              if (response.last_bu != null) {
                const lastBU = response.businessData.find(
                  (item) => item.id === response.last_bu
                );

                lastbudatav = lastBU;
                await fetchCountryCode();
              } else {
                const primerBU = response.businessData[0];
                lastbudatav = primerBU;
              }
            } catch (error) {
              const primerBU = response.businessData[0];
              lastbudatav = primerBU;
            }
          } catch (error) {
            console.error(error);
          }
          // console.log("Dispatching");
          let u = {
            id: user.uid,
            email: user.email,
            name: user.displayName || "Usuario nuevo",
            picurl: user.photoURL,
            lastbu: last_bu,
            businessData: businessData,
            sessionToken: idToken,
            lastbudata: lastbudatav,
          };
          // console.log(u);
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user: u,
            },
          });
        } else {
          dispatch({
            type: LOGOUT,
          });
        }
      });
    };
    fetchData();
  }, [dispatch]);

  const firebaseEmailPasswordSignIn = (email, password) =>
    firebase.auth().signInWithEmailAndPassword(email, password);

  const firebaseGoogleSignIn = () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    return firebase.auth().signInWithPopup(provider);
  };

  const firebaseFacebookSignIn = () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    provider.addScope("instagram_basic"); //testing now
    provider.addScope("instagram_manage_messages"); //testing now
    provider.addScope("pages_messaging");
    provider.addScope("read_page_mailboxes");
    provider.addScope("pages_show_list");
    provider.addScope("pages_read_engagement");
    provider.addScope("pages_manage_metadata");
    let FBtoken = "";
    const signin = firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        var token = result.credential.accessToken;
        var uID = result.additionalUserInfo.profile.id;
        // console.log(token);
        FBtoken = token;
        localStorage.setItem("fbauth", FBtoken);
        // localStorage.setItem("fbauth_all",JSON.stringify(result));
        localStorage.setItem("fbauthid", uID);
        return FBtoken;
      });
    return signin;
    // return result;
  };

  const firebaseRegister = async (email, password) =>
    firebase.auth().createUserWithEmailAndPassword(email, password);

  const logout = () => firebase.auth().signOut();

  const resetPassword = async (email) => {
    await firebase.auth().sendPasswordResetEmail(email);
  };

  const updateProfile = () => {};
  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <FirebaseContext.Provider
      value={{
        ...state,
        firebaseRegister,
        firebaseEmailPasswordSignIn,
        login: () => {},
        firebaseGoogleSignIn,
        firebaseFacebookSignIn,
        logout,
        resetPassword,
        updateProfile,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

FirebaseProvider.propTypes = {
  children: PropTypes.node,
};

export default FirebaseContext;
