// import { lazy } from 'react';
// import { useRoutes } from 'react-router-dom';

// // routes
// import MainRoutes from './MainRoutes';
// import LoginRoutes from './LoginRoutes';
// import AuthenticationRoutes from './AuthenticationRoutes';
// import Loadable from 'ui-component/Loadable';

// const PagesLanding = Loadable(lazy(() => import('views/pages/landing')));

// // ==============================|| ROUTING RENDER ||============================== //

// export default function ThemeRoutes() {
//     return useRoutes([{ path: '/', element: <PagesLanding /> }, AuthenticationRoutes, LoginRoutes, MainRoutes]);
// }

import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import Loadable from 'ui-component/Loadable';

const PagesLanding = Loadable(lazy(() => import('views/pages/landing')));
// const PagesLanding = Loadable(lazy(() => import('views/parking')));
const ParkingNexaiLanding = Loadable(lazy(() => import('views/parking')));


// Obtener el subdominio del hostname
const subdomain = window.location.hostname.split('.')[0];

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    // Renderizar componente condicionalmente basado en el subdominio
    const renderComponentBasedOnSubdomain = () => {
        if (subdomain === 'parking') {
            return <ParkingNexaiLanding />;
        } else {
            return <PagesLanding />;
        }
    };

    return useRoutes([
        { path: '/', element: renderComponentBasedOnSubdomain() }, 
        AuthenticationRoutes, 
        LoginRoutes, 
        MainRoutes
    ]);
}
