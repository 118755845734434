// project imports
import services from 'utils/mockAdapter';

// user list
// const dashboard = {
//     id: 'dashboard',
//     title: 'dashboard',
//     icon: 'dashboard',
//     type: 'group',
//     children: [
//         { 
//             id: 'default',
//             title: 'default',
//             type: 'item',
//             url: '/mi-nexai/inicio',
//             icon: 'dashboard',
//             breadcrumbs: false,
//             comment: 'esto es inicio'
//         },
//         {
//             id: 'analytics',
//             title: 'analytics',
//             type: 'item',
//             url: '/dashboard/analytics',
//             icon: 'device',
//             breadcrumbs: false,
//             comment: 'esto es análisis rápido'
//         }
//     ]
// };

// ==============================|| MOCK SERVICES ||============================== //

// services.onGet('/api/dashboard').reply(200, { dashboard });

services.onPost('/api/dashboard').reply(async (request) => {
    try {
        // const { business_id } = JSON.parse(request.data);
        // console.log(request.data);
        var business_id = 0;
        console.log(business_id);
        let dashboard = {}
        if (business_id == 1) // SUSTITUIR ESTE 1 CON EL ID DEL RUBRO DEL NEGOCIO O LA CONF DESDE FIREBASE DESDE LOCALSTORAGE
        {
            dashboard = {
                id: 'dashboard',
                title: 'dashboard',
                icon: 'dashboard',
                type: 'group',
                children: [
                    { 
                        id: 'default',
                        title: 'default',
                        type: 'item',
                        url: '/mi-nexai/inicio',
                        icon: 'dashboard',
                        breadcrumbs: false,
                        comment: 'esto es inicio'
                    },
                    {
                        id: 'analytics',
                        title: 'analytics',
                        type: 'item',
                        url: '/dashboard/analytics',
                        icon: 'device',
                        breadcrumbs: false,
                        comment: 'esto es análisis rápido'
                    }
                ]
            };
        }
        else{
            dashboard = {
                id: 'dashboard',
                title: 'rs_main',
                icon: 'dashboard',
                type: 'group',
                children: [
                  
                    {
                        id: 'rs_condo',
                        title: 'rs_condo',
                        type: 'item',
                        url: '/rs/groups',
                        icon: 'device',
                        breadcrumbs: true,
                        comment: 'Esta es una opción para administrar los grupos de bienes inmuebles por zona, residencial o cualquier otro método'
                    },
                    {
                        id: 'rs_prop',
                        title: 'rs_prop',
                        type: 'item',
                        url: '/rs/properties',
                        icon: 'device',
                        breadcrumbs: true,
                        comment: 'Esta es una opción para administrar los grupos de bienes inmuebles por zona, residencial o cualquier otro método'
                    }
                    ,
                    {
                        id: 'rs_inq',
                        title: 'rs_inq',
                        type: 'item',
                        url: '/rs/rentals',
                        icon: 'device',
                        breadcrumbs: true,
                        comment: 'Esta es una opción para administrar los grupos de bienes inmuebles por zona, residencial o cualquier otro método'
                    }
                ]
            };
        }
        // console.log("TORETURN",db);
        return [
            200,
            {dashboard}
        ];

    } catch (err) {
        console.error(err);
        return [500, { message: 'Server Error' }];
    }

});
