import LAYOUT_CONST from "constant";
import HouseIcon from "@mui/icons-material/Home";
import ApartmentIcon from "@mui/icons-material/Apartment";
import BungalowIcon from "@mui/icons-material/Bungalow";
import RanchoPlayaIcon from "@mui/icons-material/Deck";
import NegocioIcon from "@mui/icons-material/Store";
import LivingIcon from "@mui/icons-material/Living";
import TerrainIcon from "@mui/icons-material/Terrain";
import LakeIcon from "@mui/icons-material/Cottage";

import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import BalconyIcon from "@mui/icons-material/Balcony";
import ChildCareIcon from "@mui/icons-material/ChildCare";
import DeskIcon from "@mui/icons-material/Desk"; //area para trabajar
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter"; //gym
import OutdoorGrillIcon from "@mui/icons-material/OutdoorGrill"; //bbq
import BeachAccessIcon from "@mui/icons-material/BeachAccess"; // beach
import KayakingIcon from "@mui/icons-material/Kayaking"; //lake
import ForestIcon from "@mui/icons-material/Forest"; //area verde
import PetsIcon from "@mui/icons-material/Pets"; //pet friendly
import LocalPoliceIcon from "@mui/icons-material/LocalPolice"; //security
import ElevatorIcon from "@mui/icons-material/Elevator"; //ascensor
import RoofingIcon from "@mui/icons-material/Roofing"; //rooftop
import PoolIcon from "@mui/icons-material/Pool"; //piscina
import HotTubIcon from "@mui/icons-material/HotTub"; //jacuzzi

import LocalLaundryServiceIcon from "@mui/icons-material/LocalLaundryService"; //linea blanca
import FlatwareIcon from "@mui/icons-material/Flatware"; //parcialmente amueblado
import ChairIcon from "@mui/icons-material/Chair"; // amueblado
import BlenderIcon from "@mui/icons-material/Blender"; //Cocina

import WifiIcon from "@mui/icons-material/Wifi"; //wifi
import BoltIcon from "@mui/icons-material/Bolt"; // energia electrica
import WaterDamageIcon from "@mui/icons-material/WaterDamage"; //servicio de agua

import { Paper } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";

export const JWT_API = {
  secret: "SECRET-KEY",
  timeout: "1 days",
};

export const FIREBASE_API = {
  apiKey: "AIzaSyD4wkTJAxTZEwClJ-3XlKYx0k8qXiOIpl8",
  authDomain: "nexai-265a6.firebaseapp.com",
  projectId: "nexai-265a6",
  storageBucket: "nexai-265a6.appspot.com",
  messagingSenderId: "397796017442",
  appId: "1:397796017442:web:b503d74e893af6eef1917b",
  measurementId: "G-EDSLB98BBL",
};

export const AUTH0_API = {
  client_id: "7T4IlWis4DKHSbG8JAye4Ipk0rvXkH9V",
  domain: "dev-w0-vxep3.us.auth0.com",
};

export const AWS_API = {
  poolId: "us-east-1_AOfOTXLvD",
  appClientId: "3eau2osduslvb7vks3vsh9t7b0",
};

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = "";

// export const DASHBOARD_PATH = '/dashboard/default';
export const DASHBOARD_PATH = "/on-boarding";
export const DASHBOARD_PATH_INITIATED = "/mi-nexai/inicio";
// export const API_URL = 'https://nexai-api-admin-72slggwlhq-ue.a.run.app/api/v0/';
// export const API_URL = 'https://nexai-chat.uc.r.appspot.com/api/v0/';
// export const API_URL = 'https://nexai-technologies.uc.r.appspot.com/api/v0/';

// DEVELOPMENT
// export const API_URL = "https://nexai-265a6.uc.r.appspot.com/api/v0/";
// export const API_URL = "https://nexai-api-admin-gr4l4omq2a-ue.a.run.app/api/v0/";
export const API_URL = "https://nexai-api-admin-gr4l4omq2a-ue.a.run.app/api/v0/";
// export const API_URL = "http://127.0.0.1:8180/api/v0/";
// LOCAL
// export const API_URL = "https://67a2-88-1-94-65.ngrok-free.app/api/v0/";

export const API_KEY = "AIzaSyBmpSBUSw-Qi-h1rrijewzsxOLEibOlzbo";

// export const API_URL = 'https://nexai-chat.uc.r.appspot.com/api/v0/';
export const FBGRAPH_URL = "https://graph.facebook.com/v16.0/";

export const HORIZONTAL_MAX_ITEM = 6;

const timeOfDay = () => {
  const now = new Date();
  const hour = now.getHours();
  const isDayTime = hour >= 6 && hour < 18;
  return isDayTime ? "light" : "light";
  // return isDayTime ? "light" : "dark";
};

const paletteMode = timeOfDay();

const config = {
  layout: LAYOUT_CONST.VERTICAL_LAYOUT, // vertical, horizontal
  drawerType: LAYOUT_CONST.DEFAULT_DRAWER, // default, mini-drawer
  fontFamily: `'DM Sans', sans-serif`,
  borderRadius: 8,
  outlinedFilled: true,
  // navType: 'dark', // light, dark
  navType: paletteMode, // light, dark auto by day hours
  presetColor: "theme6", // default, theme1, theme2, theme3, theme4, theme5, theme6
  locale: "es", // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
  rtlLayout: false,
  container: true,
};

export const avatar_conf = [
  {
    // shape_style : "rounded",
    shape_style: "",
    position_avatar: "center",
    avatar_url:
      "https://firebasestorage.googleapis.com/v0/b/nexai-265a6.appspot.com/o/ss.png?alt=media&token=897cbcf5-cfdc-4624-b1d5-f240dad959eb",
    border_width: "2px",
    border_color: "#FFF",
  },
];

export const business_location = [
  {
    id: 1,
    address: "87 Av. Norte #118B Colonia Escalón San Salvador",
    ref: "Frente a la oficina central del MOP, puerta celeste con verde.",
    location: {
      lat: -89.12233,
      lng: "13.12430",
    },
    waze_link: "https://waze.com/AS384Af",
    maps_link: "https://goo.gle/askdask",
    location_name: "San Salvador - Escalón",
  },
];
export const horarios = [
  {
    id: 1,
    dayName: "Domingo",
    startTime: "Cerrado",
    endTime: "Cerrado",
    workable: false,
  },
  {
    id: 2,
    dayName: "Lunes",
    startTime: "07:00",
    endTime: "21:00",
    workable: true,
  },
  {
    id: 3,
    dayName: "Martes",
    startTime: "07:00",
    endTime: "21:00",
    workable: true,
  },
  {
    id: 4,
    dayName: "Miércoles",
    startTime: "07:00",
    endTime: "19:00",
    workable: true,
  },
  {
    id: 5,
    dayName: "Jueves",
    startTime: "07:00",
    endTime: "19:00",
    workable: true,
  },
  {
    id: 6,
    dayName: "Viernes",
    startTime: "07:00",
    endTime: "23:00",
    workable: true,
  },
  {
    id: 7,
    dayName: "Sábado",
    startTime: "09:00",
    endTime: "23:00",
    workable: true,
  },
];
export const headerBanner = [
  {
    id: 1,
    url: "https://firebasestorage.googleapis.com/v0/b/nexai-265a6.appspot.com/o/1500x500%20(1).jpeg?alt=media&token=7b0c6e84-a369-46d2-8541-7b83b7a22e4c",
  },
  {
    id: 2,
    url: "https://firebasestorage.googleapis.com/v0/b/nexai-265a6.appspot.com/o/gastronomia-el-salvador--scaled.webp?alt=media&token=8a3626b1-5b8c-4261-bdc3-1261ddbc6f6a",
  },
  {
    id: 3,
    url: "https://firebasestorage.googleapis.com/v0/b/nexai-265a6.appspot.com/o/REVUELTA-TIPICOS-MARGOTH.jpg?alt=media&token=fbe1fbe8-fbf5-494e-ac57-bdaff1d27b53",
  },
  {
    id: 4,
    url: "https://firebasestorage.googleapis.com/v0/b/nexai-265a6.appspot.com/o/TIPICOS-MARGOTH-OK.jpg?alt=media&token=740d0852-c560-4232-8076-a23f93e2ef1d",
  },
];
export const products = [
  {
    id: 1,
    name: "Pupusas Frijol con queso",
    price: 1.0,
    description: "Pupusas tradicionales de masa y relleno de frijol con queso",
    image:
      "https://cookingwithkids.org/wp-content/uploads/2022/12/Pupusas_750x750.jpg",
    category: "Combos",
    options: {
      "Ingredientes adicionales": [
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.25,
          id: 1,
        },
        {
          name: "Queso extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.5,
          id: 2,
        },
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 0.25,
          id: 3,
        },
      ],
      Tamaño: [
        {
          name: "Pequeño",
          price: 0,
          id: 4,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Mediano",
          price: 0.5,
          id: 5,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Grande",
          price: 1.0,
          id: 6,
          required: true,
          min: 1,
          max: 2,
        },
      ],
      Complementos: [
        {
          name: "Salsa extra",
          price: 0.75,
          id: 7,
          required: true,
          min: 1,
          max: 2,
        },
        // {
        //   name: "Ensalada",
        //   price: 1.00,
        //   id: 8,
        //   required: true,
        //   min: 1,
        //   max: 2,
        // },
      ],
    },
  },
  {
    id: 2,
    name: "Pupusas revueltas",
    price: 1.25,
    description: "Pupusas tradicionales de masa y relleno de frijol con queso",
    image:
      "https://assets.unileversolutions.com/recipes-v2/232005.jpg?im=Resize,height=530;Crop,size=(540,530),gravity=Center",
    category: "Ofertas",
    options: {
      "Ingredientes adicionales": [
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: true,
          price: 1.25,
          id: 1,
        },
        {
          name: "Queso extra",
          min: 1,
          max: 2,
          required: true,
          price: 1.5,
          id: 2,
        },
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 0.25,
          id: 3,
        },
      ],
      Tamaño: [
        {
          name: "Pequeño",
          price: 0,
          id: 4,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Mediano",
          price: 0.5,
          id: 5,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Grande",
          price: 1.0,
          id: 6,
          required: true,
          min: 1,
          max: 2,
        },
      ],
      Complementos: [
        {
          name: "Salsa extra",
          price: 0.75,
          id: 7,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Ensalada",
          price: 1.0,
          id: 8,
          required: true,
          min: 1,
          max: 2,
        },
      ],
    },
  },
  {
    id: 3,
    name: "Pupusas Pescado",
    price: 1.25,
    description: "Pupusas tradicionales de masa y relleno de frijol con queso",
    image:
      "https://www.foodandwine.com/thmb/tLoL7wei0D2lMe1vYEVDj_JrHvI=/750x0/filters:no_upscale():max_bytes(150000):strip_icc():format(webp)/Bean-and-Cheese-Pupusas-FT-RECIPE0523-29be211a90054437b397d0fae65d75df.jpg",
    category: "Combos",
    options: {
      "Ingredientes adicionales": [
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.25,
          id: 1,
        },
        {
          name: "Queso extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.5,
          id: 2,
        },
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 0.25,
          id: 3,
        },
      ],
      Tamaño: [
        {
          name: "Pequeño",
          price: 0,
          id: 4,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Mediano",
          price: 5,
          id: 5,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Grande",
          price: 1.0,
          id: 6,
          required: true,
          min: 1,
          max: 2,
        },
      ],
      Complementos: [
        {
          name: "Salsa extra",
          price: 0.75,
          id: 7,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Ensalada",
          price: 1.0,
          id: 8,
          required: true,
          min: 1,
          max: 2,
        },
      ],
    },
  },
  {
    id: 4,
    name: "Pupusas Frijol",
    price: 1.25,
    description: "Pupusas tradicionales de masa y relleno de frijol con queso",
    image:
      "https://assets.unileversolutions.com/recipes-v2/232005.jpg?im=Resize,height=530;Crop,size=(540,530),gravity=Center",
    category: "Económicos",
    options: {
      "Ingredientes adicionales": [
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.25,
          id: 1,
        },
        {
          name: "Queso extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.5,
          id: 2,
        },
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 0.25,
          id: 3,
        },
      ],
      Tamaño: [
        {
          name: "Pequeño",
          price: 0,
          id: 4,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Mediano",
          price: 0.5,
          id: 5,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Grande",
          price: 1.0,
          id: 6,
          required: true,
          min: 1,
          max: 2,
        },
      ],
      Complementos: [
        {
          name: "Salsa extra",
          price: 0.75,
          id: 7,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Ensalada",
          price: 1.0,
          id: 8,
          required: true,
          min: 1,
          max: 2,
        },
      ],
    },
  },

  {
    id: 5,
    name: "Pupusas Chicharrón con queso",
    price: 1.25,
    description: "Pupusas tradicionales de masa y relleno de frijol con queso",
    image:
      "https://cookingwithkids.org/wp-content/uploads/2022/12/Pupusas_750x750.jpg",
    category: "Para compartir",
    options: {
      "Ingredientes adicionales": [
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.25,
          id: 1,
        },
        {
          name: "Queso extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.5,
          id: 2,
        },
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 0.25,
          id: 3,
        },
      ],
      Tamaño: [
        {
          name: "Pequeño",
          price: 0,
          id: 4,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Mediano",
          price: 0.5,
          id: 5,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Grande",
          price: 1.0,
          id: 6,
          required: true,
          min: 1,
          max: 2,
        },
      ],
      Complementos: [
        {
          name: "Salsa extra",
          price: 0.75,
          id: 7,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Ensalada",
          price: 1.0,
          id: 8,
          required: true,
          min: 1,
          max: 2,
        },
      ],
    },
  },
  {
    id: 6,
    name: "Pupusas Queso",
    price: 1.25,
    description: "Pupusas tradicionales de masa y relleno de frijol con queso",
    image:
      "https://assets.unileversolutions.com/recipes-v2/232005.jpg?im=Resize,height=530;Crop,size=(540,530),gravity=Center",
    category: "Ofertas",
    options: {
      "Ingredientes adicionales": [
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.25,
          id: 1,
        },
        {
          name: "Queso extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.5,
          id: 2,
        },
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 0.25,
          id: 3,
        },
      ],
      Tamaño: [
        {
          name: "Pequeño",
          price: 0,
          id: 4,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Mediano",
          price: 0.5,
          id: 5,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Grande",
          price: 1.0,
          id: 6,
          required: true,
          min: 1,
          max: 2,
        },
      ],
      Complementos: [
        {
          name: "Salsa extra",
          price: 0.75,
          id: 7,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Ensalada",
          price: 1.0,
          id: 8,
          required: true,
          min: 1,
          max: 2,
        },
      ],
    },
  },
  {
    id: 7,
    name: "Pupusas Queso con loroco",
    price: 1.0,
    description: "Pupusas tradicionales de masa y relleno de frijol con queso",
    image:
      "https://cookingwithkids.org/wp-content/uploads/2022/12/Pupusas_750x750.jpg",
    category: "Ofertas",
    options: {
      "Ingredientes adicionales": [
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.25,
          id: 1,
        },
        {
          name: "Queso extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.5,
          id: 2,
        },
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 0.25,
          id: 3,
        },
      ],
      Tamaño: [
        {
          name: "Pequeño",
          price: 0,
          id: 4,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Mediano",
          price: 0.5,
          id: 5,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Grande",
          price: 1.0,
          id: 6,
          required: true,
          min: 1,
          max: 2,
        },
      ],
      Complementos: [
        {
          name: "Salsa extra",
          price: 0.75,
          id: 7,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Ensalada",
          price: 1.0,
          id: 8,
          required: true,
          min: 1,
          max: 2,
        },
      ],
    },
  },
  {
    id: 8,
    name: "Pupusas Queso con ajo",
    price: 1.0,
    description: "Pupusas tradicionales de masa y relleno de frijol con queso",
    image:
      "https://assets.unileversolutions.com/recipes-v2/232005.jpg?im=Resize,height=530;Crop,size=(540,530),gravity=Center",
    category: "Ofertas",
    options: {
      "Ingredientes adicionales": [
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.25,
          id: 1,
        },
        {
          name: "Queso extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.5,
          id: 2,
        },
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 0.25,
          id: 3,
        },
      ],
      Tamaño: [
        {
          name: "Pequeño",
          price: 0,
          id: 4,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Mediano",
          price: 0.5,
          id: 5,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Grande",
          price: 1.0,
          id: 6,
          required: true,
          min: 1,
          max: 2,
        },
      ],
      Complementos: [
        {
          name: "Salsa extra",
          price: 0.75,
          id: 7,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Ensalada",
          price: 1.0,
          id: 8,
          required: true,
          min: 1,
          max: 2,
        },
      ],
    },
  },
  {
    id: 9,
    name: "Pupusas Queso con ayote",
    price: 1.0,
    description: "Pupusas tradicionales de masa y relleno de frijol con queso",
    image:
      "https://cookingwithkids.org/wp-content/uploads/2022/12/Pupusas_750x750.jpg",
    category: "Ofertas",
    options: {
      "Ingredientes adicionales": [
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.25,
          id: 1,
        },
        {
          name: "Queso extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.5,
          id: 2,
        },
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 0.25,
          id: 3,
        },
      ],
      Tamaño: [
        {
          name: "Pequeño",
          price: 0,
          id: 4,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Mediano",
          price: 0.5,
          id: 5,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Grande",
          price: 1.0,
          id: 6,
          required: true,
          min: 1,
          max: 2,
        },
      ],
      Complementos: [
        {
          name: "Salsa extra",
          price: 0.75,
          id: 7,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Ensalada",
          price: 1.0,
          id: 8,
          required: true,
          min: 1,
          max: 2,
        },
      ],
    },
  },
  {
    id: 10,
    name: "Pupusas Queso con chipilin",
    price: 1.0,
    description: "Pupusas tradicionales de masa y relleno de frijol con queso",
    image:
      "https://assets.unileversolutions.com/recipes-v2/232005.jpg?im=Resize,height=530;Crop,size=(540,530),gravity=Center",
    category: "Especiales",
    options: {
      "Ingredientes adicionales": [
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.25,
          id: 1,
        },
        {
          name: "Queso extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.5,
          id: 2,
        },
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 0.25,
          id: 3,
        },
      ],
      Tamaño: [
        {
          name: "Pequeño",
          price: 0,
          id: 4,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Mediano",
          price: 0.5,
          id: 5,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Grande",
          price: 1.0,
          id: 6,
          required: true,
          min: 1,
          max: 2,
        },
      ],
      Complementos: [
        {
          name: "Salsa extra",
          price: 0.75,
          id: 7,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Ensalada",
          price: 1.0,
          id: 8,
          required: true,
          min: 1,
          max: 2,
        },
      ],
    },
  },

  {
    id: 11,
    name: "Pupusas Queso con ayote",
    price: 1.0,
    description: "Pupusas tradicionales de masa y relleno de frijol con queso",
    image:
      "https://assets.unileversolutions.com/recipes-v2/232005.jpg?im=Resize,height=530;Crop,size=(540,530),gravity=Center",
    category: "Especiales",
    options: {
      "Ingredientes adicionales": [
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.25,
          id: 1,
        },
        {
          name: "Queso extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.5,
          id: 2,
        },
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 0.25,
          id: 3,
        },
      ],
      Tamaño: [
        {
          name: "Pequeño",
          price: 0,
          id: 4,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Mediano",
          price: 0.5,
          id: 5,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Grande",
          price: 1.0,
          id: 6,
          required: true,
          min: 1,
          max: 2,
        },
      ],
      Complementos: [
        {
          name: "Salsa extra",
          price: 0.75,
          id: 7,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Ensalada",
          price: 1.0,
          id: 8,
          required: true,
          min: 1,
          max: 2,
        },
      ],
    },
  },

  {
    id: 12,
    name: "Pupusas Queso con cilantro",
    price: 1.0,
    currency: "USD",
    description: "Pupusas tradicionales de masa y relleno de frijol con queso",
    image:
      "https://assets.unileversolutions.com/recipes-v2/232005.jpg?im=Resize,height=530;Crop,size=(540,530),gravity=Center",
    category: "Especiales",
    options: {
      "Ingredientes adicionales": [
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.25,
          id: 1,
        },
        {
          name: "Queso extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.5,
          id: 2,
        },
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 0.25,
          id: 3,
        },
      ],
      Tamaño: [
        {
          name: "Pequeño",
          price: 0,
          id: 4,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Mediano",
          price: 0.5,
          id: 5,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Grande",
          price: 1.0,
          id: 6,
          required: true,
          min: 1,
          max: 2,
        },
      ],
      Complementos: [
        {
          name: "Salsa extra",
          price: 0.75,
          id: 7,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Ensalada",
          price: 1.0,
          id: 8,
          required: true,
          min: 1,
          max: 2,
        },
      ],
    },
  },

  {
    id: 13,
    name: "Pupusas Queso con loroco",
    price: 1.25,
    description: "Pupusas tradicionales de masa y relleno de frijol con queso",
    image:
      "https://assets.unileversolutions.com/recipes-v2/232005.jpg?im=Resize,height=530;Crop,size=(540,530),gravity=Center",
    category: "Especiales",
    options: {
      "Ingredientes adicionales": [
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.25,
          id: 1,
        },
        {
          name: "Queso extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.5,
          id: 2,
        },
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 0.25,
          id: 3,
        },
      ],
      Tamaño: [
        {
          name: "Pequeño",
          price: 0,
          id: 4,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Mediano",
          price: 0.5,
          id: 5,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Grande",
          price: 1.0,
          id: 6,
          required: true,
          min: 1,
          max: 2,
        },
      ],
      Complementos: [
        {
          name: "Salsa extra",
          price: 0.75,
          id: 7,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Ensalada",
          price: 1.0,
          id: 8,
          required: true,
          min: 1,
          max: 2,
        },
      ],
    },
  },

  {
    id: 14,
    name: "Pupusas Queso con jalapeño",
    price: 1.0,
    description: "Pupusas tradicionales de masa y relleno de frijol con queso",
    image:
      "https://assets.unileversolutions.com/recipes-v2/232005.jpg?im=Resize,height=530;Crop,size=(540,530),gravity=Center",
    category: "Las de siempre",
    options: {
      "Ingredientes adicionales": [
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.25,
          id: 1,
        },
        {
          name: "Queso extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.5,
          id: 2,
        },
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 0.25,
          id: 3,
        },
      ],
      Tamaño: [
        {
          name: "Pequeño",
          price: 0,
          id: 4,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Mediano",
          price: 0.5,
          id: 5,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Grande",
          price: 1.0,
          id: 6,
          required: true,
          min: 1,
          max: 2,
        },
      ],
      Complementos: [
        {
          name: "Salsa extra",
          price: 0.75,
          id: 7,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Ensalada",
          price: 1.0,
          id: 8,
          required: true,
          min: 1,
          max: 2,
        },
      ],
    },
  },

  {
    id: 15,
    name: "Pupusas Chicharron, queso y jalapeño",
    price: 1.0,
    description: "Pupusas tradicionales de masa y relleno de frijol con queso",
    image:
      "https://assets.unileversolutions.com/recipes-v2/232005.jpg?im=Resize,height=530;Crop,size=(540,530),gravity=Center",
    category: "Especiales",
    options: {
      "Ingredientes adicionales": [
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.25,
          id: 1,
        },
        {
          name: "Queso extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.5,
          id: 2,
        },
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 0.25,
          id: 3,
        },
      ],
      Tamaño: [
        {
          name: "Pequeño",
          price: 0,
          id: 4,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Mediano",
          price: 0.5,
          id: 5,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Grande",
          price: 1.0,
          id: 6,
          required: true,
          min: 1,
          max: 2,
        },
      ],
      Complementos: [
        {
          name: "Salsa extra",
          price: 0.75,
          id: 7,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Ensalada",
          price: 1.0,
          id: 8,
          required: true,
          min: 1,
          max: 2,
        },
      ],
    },
  },

  {
    id: 16,
    name: "Pupusas Chicharron, queso y loroco",
    price: 1.0,
    description: "Pupusas tradicionales de masa y relleno de frijol con queso",
    image:
      "https://assets.unileversolutions.com/recipes-v2/232005.jpg?im=Resize,height=530;Crop,size=(540,530),gravity=Center",
    category: "Especiales",
    options: {
      "Ingredientes adicionales": [
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.25,
          id: 1,
        },
        {
          name: "Queso extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.5,
          id: 2,
        },
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 0.25,
          id: 3,
        },
      ],
      Tamaño: [
        {
          name: "Pequeño",
          price: 0,
          id: 4,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Mediano",
          price: 0.5,
          id: 5,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Grande",
          price: 1.0,
          id: 6,
          required: true,
          min: 1,
          max: 2,
        },
      ],
      Complementos: [
        {
          name: "Salsa extra",
          price: 0.75,
          id: 7,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Ensalada",
          price: 1.0,
          id: 8,
          required: true,
          min: 1,
          max: 2,
        },
      ],
    },
  },

  {
    id: 17,
    name: "Pupusas Queso, ajo y cilantro",
    price: 1.0,
    description: "Pupusas tradicionales de masa y relleno de frijol con queso",
    image:
      "https://assets.unileversolutions.com/recipes-v2/232005.jpg?im=Resize,height=530;Crop,size=(540,530),gravity=Center",
    category: "Las de siempre",
    options: {
      "Ingredientes adicionales": [
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.25,
          id: 1,
        },
        {
          name: "Queso extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.5,
          id: 2,
        },
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 0.25,
          id: 3,
        },
      ],
      Tamaño: [
        {
          name: "Pequeño",
          price: 0,
          id: 4,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Mediano",
          price: 0.5,
          id: 5,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Grande",
          price: 1.0,
          id: 6,
          required: true,
          min: 1,
          max: 2,
        },
      ],
      Complementos: [
        {
          name: "Salsa extra",
          price: 0.75,
          id: 7,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Ensalada",
          price: 1.0,
          id: 8,
          required: true,
          min: 1,
          max: 2,
        },
      ],
    },
  },

  {
    id: 18,
    name: "Pupusas Frijol, queso y loroco",
    price: 1.0,
    description: "Pupusas tradicionales de masa y relleno de frijol con queso",
    image:
      "https://assets.unileversolutions.com/recipes-v2/232005.jpg?im=Resize,height=530;Crop,size=(540,530),gravity=Center",
    category: "Las de siempre",
    options: {
      "Ingredientes adicionales": [
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.25,
          id: 1,
        },
        {
          name: "Queso extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.5,
          id: 2,
        },
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 0.25,
          id: 3,
        },
      ],
      Tamaño: [
        {
          name: "Pequeño",
          price: 0,
          id: 4,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Mediano",
          price: 0.5,
          id: 5,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Grande",
          price: 1.0,
          id: 6,
          required: true,
          min: 1,
          max: 2,
        },
      ],
      Complementos: [
        {
          name: "Salsa extra",
          price: 0.75,
          id: 7,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Ensalada",
          price: 1.0,
          id: 8,
          required: true,
          min: 1,
          max: 2,
        },
      ],
    },
  },

  {
    id: 19,
    name: "Pupusas revueltas con ajo",
    price: 1.25,
    description: "Pupusas tradicionales de masa y relleno de frijol con queso",
    image:
      "https://assets.unileversolutions.com/recipes-v2/232005.jpg?im=Resize,height=530;Crop,size=(540,530),gravity=Center",
    category: "Las de siempre",
    options: {
      "Ingredientes adicionales": [
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.25,
          id: 1,
        },
        {
          name: "Queso extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.5,
          id: 2,
        },
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 0.25,
          id: 3,
        },
      ],
      Tamaño: [
        {
          name: "Pequeño",
          price: 0,
          id: 4,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Mediano",
          price: 0.5,
          id: 5,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Grande",
          price: 1.0,
          id: 6,
          required: true,
          min: 1,
          max: 2,
        },
      ],
      Complementos: [
        {
          name: "Salsa extra",
          price: 0.75,
          id: 7,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Ensalada",
          price: 1.0,
          id: 8,
          required: true,
          min: 1,
          max: 2,
        },
      ],
    },
  },

  {
    id: 20,
    name: "Pupusas revueltas con jalapeño",
    price: 1.0,
    description: "Pupusas tradicionales de masa y relleno de frijol con queso",
    image:
      "https://assets.unileversolutions.com/recipes-v2/232005.jpg?im=Resize,height=530;Crop,size=(540,530),gravity=Center",
    category: "Las de siempre",
    options: {
      "Ingredientes adicionales": [
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.25,
          id: 1,
        },
        {
          name: "Queso extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.5,
          id: 2,
        },
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 0.25,
          id: 3,
        },
      ],
      Tamaño: [
        {
          name: "Pequeño",
          price: 0,
          id: 4,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Mediano",
          price: 0.5,
          id: 5,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Grande",
          price: 1.0,
          id: 6,
          required: true,
          min: 1,
          max: 2,
        },
      ],
      Complementos: [
        {
          name: "Salsa extra",
          price: 0.75,
          id: 7,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Ensalada",
          price: 1.0,
          id: 8,
          required: true,
          min: 1,
          max: 2,
        },
      ],
    },
  },
  {
    id: 21,
    name: "Cebada sin leche",
    price: 2.0,
    description: "Cebada tradicional sin leche",
    image:
      "https://recetassalvadorenas.com/wp-content/uploads/2017/02/fresco-de-cebada.jpg",
    category: "Bebidas",
    options: {
      "Ingredientes adicionales": [
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.25,
          id: 1,
        },
        {
          name: "Queso extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.5,
          id: 2,
        },
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 0.25,
          id: 3,
        },
      ],
      Tamaño: [
        {
          name: "Pequeño",
          price: 0,
          id: 4,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Mediano",
          price: 0.5,
          id: 5,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Grande",
          price: 1.0,
          id: 6,
          required: true,
          min: 1,
          max: 2,
        },
      ],
      Complementos: [
        {
          name: "Salsa extra",
          price: 0.75,
          id: 7,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Ensalada",
          price: 1.0,
          id: 8,
          required: true,
          min: 1,
          max: 2,
        },
      ],
    },
  },

  {
    id: 21,
    name: "Cebada con leche",
    price: 2.5,
    description: "Cebada tradicional con leche",
    image:
      "https://recetassalvadorenas.com/wp-content/uploads/2017/02/fresco-de-cebada.jpg",
    category: "Bebidas",
    options: {
      "Ingredientes adicionales": [
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.25,
          id: 1,
        },
        {
          name: "Queso extra",
          min: 1,
          max: 2,
          required: false,
          price: 1.5,
          id: 2,
        },
        {
          name: "Chicharrón extra",
          min: 1,
          max: 2,
          required: false,
          price: 0.25,
          id: 3,
        },
      ],
      Tamaño: [
        {
          name: "Pequeño",
          price: 0,
          id: 4,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Mediano",
          price: 0.5,
          id: 5,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Grande",
          price: 1.0,
          id: 6,
          required: true,
          min: 1,
          max: 2,
        },
      ],
      Complementos: [
        {
          name: "Salsa extra",
          price: 0.75,
          id: 7,
          required: true,
          min: 1,
          max: 2,
        },
        {
          name: "Ensalada",
          price: 1.0,
          id: 8,
          required: true,
          min: 1,
          max: 2,
        },
      ],
    },
  },
];

export const businessTypeList = [
  {
    value: "1",
    label: "Agencia de viajes",
  },
  {
    value: "2",
    label: "Clínica de estética",
  },
  {
    value: "3",
    label: "Clínica médica",
  },
  {
    value: "4",
    label: "Clínica dental",
  },
  {
    value: "5",
    label: "Comercios en línea",
  },
  {
    value: "6",
    label: "Edificios de apartamentos",
  },
  {
    value: "7",
    label: "Servicio de administración de residencias",
  },
  {
    value: "8",
    label: "Escuelas e institutos",
  },
  {
    value: "9",
    label: "Centros de educación",
  },
  {
    value: "10",
    label: "Ferreterías",
  },
  {
    value: "11",
    label: "Floristerías",
  },
  {
    value: "12",
    label: "Hoteles",
  },
  {
    value: "13",
    label: "Joyería y negocios de bisutería",
  },
  {
    value: "14",
    label: "Moda y tiendas de ropa",
  },
  {
    value: "15",
    label: "Panaderías",
  },
  {
    value: "16",
    label: "Tienda de mascotas",
  },
  {
    value: "17",
    label: "Salones de belleza",
  },
  {
    value: "18",
    label: "Supermercados / Mini-market",
  },
  {
    value: "19",
    label: "Transporte y turismo",
  },
  {
    value: "20",
    label: "Ventas desde casa",
  },
  {
    value: "21",
    label: "Viveros",
  },
  {
    value: "22",
    label: "Otro tipo de negocio",
  },
  {
    value: "23",
    label: "Restaurantes",
  },
  {
    value: "24",
    label: "Alquiler de propiedades tradicional",
  },
  {
    value: "25",
    label: "Alquiler de vehículos",
  },
  {
    value: "26",
    label: "Alquiler de alojamientos vacacionales (Airbnb y otros)",
  },
  {
    value: "27",
    label: "Ópticas",
  },
];

export function getStates(gt, callback) {
  var myHeaders = new Headers();
  myHeaders.append(
    "X-CSCAPI-KEY",
    "djdGbnZYOXJGbDlVR2NuNDVLeHZWbXJqanU0T2Qyb1BkYUlZeDZMMQ=="
  );

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  fetch(API_URL + `states/${gt}`, requestOptions)
    .then((response) => response.text())
    .then((result) => callback(result))
    .catch((error) => console.log("error", error));
}

export function getCities(department, country, callback) {
  var myHeaders = new Headers();
  myHeaders.append(
    "X-CSCAPI-KEY",
    "djdGbnZYOXJGbDlVR2NuNDVLeHZWbXJqanU0T2Qyb1BkYUlZeDZMMQ=="
  );

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  fetch(API_URL + `${country}/${department}`, requestOptions)
    .then((response) => response.text())
    .then((result) => callback(result))
    .catch((error) => console.log("error", error));
}

export const countryList = [
  { id: 1, name: "Afghanistan", iso2: "AF" },
  { id: 2, name: "Aland Islands", iso2: "AX" },
  { id: 3, name: "Albania", iso2: "AL" },
  { id: 4, name: "Algeria", iso2: "DZ" },
  { id: 5, name: "American Samoa", iso2: "AS" },
  { id: 6, name: "Andorra", iso2: "AD" },
  { id: 7, name: "Angola", iso2: "AO" },
  { id: 8, name: "Anguilla", iso2: "AI" },
  { id: 9, name: "Antarctica", iso2: "AQ" },
  { id: 10, name: "Antigua And Barbuda", iso2: "AG" },
  { id: 11, name: "Argentina", iso2: "AR" },
  { id: 12, name: "Armenia", iso2: "AM" },
  { id: 13, name: "Aruba", iso2: "AW" },
  { id: 14, name: "Australia", iso2: "AU" },
  { id: 15, name: "Austria", iso2: "AT" },
  { id: 16, name: "Azerbaijan", iso2: "AZ" },
  { id: 17, name: "The Bahamas", iso2: "BS" },
  { id: 18, name: "Bahrain", iso2: "BH" },
  { id: 19, name: "Bangladesh", iso2: "BD" },
  { id: 20, name: "Barbados", iso2: "BB" },
  { id: 21, name: "Belarus", iso2: "BY" },
  { id: 22, name: "Belgium", iso2: "BE" },
  { id: 23, name: "Belize", iso2: "BZ" },
  { id: 24, name: "Benin", iso2: "BJ" },
  { id: 25, name: "Bermuda", iso2: "BM" },
  { id: 26, name: "Bhutan", iso2: "BT" },
  { id: 27, name: "Bolivia", iso2: "BO" },
  { id: 28, name: "Bosnia and Herzegovina", iso2: "BA" },
  { id: 29, name: "Botswana", iso2: "BW" },
  { id: 30, name: "Bouvet Island", iso2: "BV" },
  { id: 31, name: "Brazil", iso2: "BR" },
  { id: 32, name: "British Indian Ocean Territory", iso2: "IO" },
  { id: 33, name: "Brunei", iso2: "BN" },
  { id: 34, name: "Bulgaria", iso2: "BG" },
  { id: 35, name: "Burkina Faso", iso2: "BF" },
  { id: 36, name: "Burundi", iso2: "BI" },
  { id: 37, name: "Cambodia", iso2: "KH" },
  { id: 38, name: "Cameroon", iso2: "CM" },
  { id: 39, name: "Canada", iso2: "CA" },
  { id: 40, name: "Cape Verde", iso2: "CV" },
  { id: 41, name: "Cayman Islands", iso2: "KY" },
  { id: 42, name: "Central African Republic", iso2: "CF" },
  { id: 43, name: "Chad", iso2: "TD" },
  { id: 44, name: "Chile", iso2: "CL" },
  { id: 45, name: "China", iso2: "CN" },
  { id: 46, name: "Christmas Island", iso2: "CX" },
  { id: 47, name: "Cocos (Keeling) Islands", iso2: "CC" },
  { id: 48, name: "Colombia", iso2: "CO" },
  { id: 49, name: "Comoros", iso2: "KM" },
  { id: 50, name: "Congo", iso2: "CG" },
  { id: 51, name: "Democratic Republic of the Congo", iso2: "CD" },
  { id: 52, name: "Cook Islands", iso2: "CK" },
  { id: 53, name: "Costa Rica", iso2: "CR" },
  { id: 54, name: "Cote D'Ivoire (Ivory Coast)", iso2: "CI" },
  { id: 55, name: "Croatia", iso2: "HR" },
  { id: 56, name: "Cuba", iso2: "CU" },
  { id: 57, name: "Cyprus", iso2: "CY" },
  { id: 58, name: "Czech Republic", iso2: "CZ" },
  { id: 59, name: "Denmark", iso2: "DK" },
  { id: 60, name: "Djibouti", iso2: "DJ" },
  { id: 61, name: "Dominica", iso2: "DM" },
  { id: 62, name: "Dominican Republic", iso2: "DO" },
  { id: 63, name: "East Timor", iso2: "TL" },
  { id: 64, name: "Ecuador", iso2: "EC" },
  { id: 65, name: "Egypt", iso2: "EG" },
  { id: 66, name: "El Salvador", iso2: "SV" },
  { id: 67, name: "Equatorial Guinea", iso2: "GQ" },
  { id: 68, name: "Eritrea", iso2: "ER" },
  { id: 69, name: "Estonia", iso2: "EE" },
  { id: 70, name: "Ethiopia", iso2: "ET" },
  { id: 71, name: "Falkland Islands", iso2: "FK" },
  { id: 72, name: "Faroe Islands", iso2: "FO" },
  { id: 73, name: "Fiji Islands", iso2: "FJ" },
  { id: 74, name: "Finland", iso2: "FI" },
  { id: 75, name: "France", iso2: "FR" },
  { id: 76, name: "French Guiana", iso2: "GF" },
  { id: 77, name: "French Polynesia", iso2: "PF" },
  { id: 78, name: "French Southern Territories", iso2: "TF" },
  { id: 79, name: "Gabon", iso2: "GA" },
  { id: 80, name: "Gambia The", iso2: "GM" },
  { id: 81, name: "Georgia", iso2: "GE" },
  { id: 82, name: "Germany", iso2: "DE" },
  { id: 83, name: "Ghana", iso2: "GH" },
  { id: 84, name: "Gibraltar", iso2: "GI" },
  { id: 85, name: "Greece", iso2: "GR" },
  { id: 86, name: "Greenland", iso2: "GL" },
  { id: 87, name: "Grenada", iso2: "GD" },
  { id: 88, name: "Guadeloupe", iso2: "GP" },
  { id: 89, name: "Guam", iso2: "GU" },
  { id: 90, name: "Guatemala", iso2: "GT" },
  { id: 91, name: "Guernsey and Alderney", iso2: "GG" },
  { id: 92, name: "Guinea", iso2: "GN" },
  { id: 93, name: "Guinea-Bissau", iso2: "GW" },
  { id: 94, name: "Guyana", iso2: "GY" },
  { id: 95, name: "Haiti", iso2: "HT" },
  { id: 96, name: "Heard Island and McDonald Islands", iso2: "HM" },
  { id: 97, name: "Honduras", iso2: "HN" },
  { id: 98, name: "Hong Kong S.A.R.", iso2: "HK" },
  { id: 99, name: "Hungary", iso2: "HU" },
  { id: 100, name: "Iceland", iso2: "IS" },
  { id: 101, name: "India", iso2: "IN" },
  { id: 102, name: "Indonesia", iso2: "ID" },
  { id: 103, name: "Iran", iso2: "IR" },
  { id: 104, name: "Iraq", iso2: "IQ" },
  { id: 105, name: "Ireland", iso2: "IE" },
  { id: 106, name: "Israel", iso2: "IL" },
  { id: 107, name: "Italy", iso2: "IT" },
  { id: 108, name: "Jamaica", iso2: "JM" },
  { id: 109, name: "Japan", iso2: "JP" },
  { id: 110, name: "Jersey", iso2: "JE" },
  { id: 111, name: "Jordan", iso2: "JO" },
  { id: 112, name: "Kazakhstan", iso2: "KZ" },
  { id: 113, name: "Kenya", iso2: "KE" },
  { id: 114, name: "Kiribati", iso2: "KI" },
  { id: 115, name: "North Korea", iso2: "KP" },
  { id: 116, name: "South Korea", iso2: "KR" },
  { id: 117, name: "Kuwait", iso2: "KW" },
  { id: 118, name: "Kyrgyzstan", iso2: "KG" },
  { id: 119, name: "Laos", iso2: "LA" },
  { id: 120, name: "Latvia", iso2: "LV" },
  { id: 121, name: "Lebanon", iso2: "LB" },
  { id: 122, name: "Lesotho", iso2: "LS" },
  { id: 123, name: "Liberia", iso2: "LR" },
  { id: 124, name: "Libya", iso2: "LY" },
  { id: 125, name: "Liechtenstein", iso2: "LI" },
  { id: 126, name: "Lithuania", iso2: "LT" },
  { id: 127, name: "Luxembourg", iso2: "LU" },
  { id: 128, name: "Macau S.A.R.", iso2: "MO" },
  { id: 129, name: "North Macedonia", iso2: "MK" },
  { id: 130, name: "Madagascar", iso2: "MG" },
  { id: 131, name: "Malawi", iso2: "MW" },
  { id: 132, name: "Malaysia", iso2: "MY" },
  { id: 133, name: "Maldives", iso2: "MV" },
  { id: 134, name: "Mali", iso2: "ML" },
  { id: 135, name: "Malta", iso2: "MT" },
  { id: 136, name: "Man (Isle of)", iso2: "IM" },
  { id: 137, name: "Marshall Islands", iso2: "MH" },
  { id: 138, name: "Martinique", iso2: "MQ" },
  { id: 139, name: "Mauritania", iso2: "MR" },
  { id: 140, name: "Mauritius", iso2: "MU" },
  { id: 141, name: "Mayotte", iso2: "YT" },
  { id: 142, name: "Mexico", iso2: "MX" },
  { id: 143, name: "Micronesia", iso2: "FM" },
  { id: 144, name: "Moldova", iso2: "MD" },
  { id: 145, name: "Monaco", iso2: "MC" },
  { id: 146, name: "Mongolia", iso2: "MN" },
  { id: 147, name: "Montenegro", iso2: "ME" },
  { id: 148, name: "Montserrat", iso2: "MS" },
  { id: 149, name: "Morocco", iso2: "MA" },
  { id: 150, name: "Mozambique", iso2: "MZ" },
  { id: 151, name: "Myanmar", iso2: "MM" },
  { id: 152, name: "Namibia", iso2: "NA" },
  { id: 153, name: "Nauru", iso2: "NR" },
  { id: 154, name: "Nepal", iso2: "NP" },
  { id: 155, name: "Bonaire, Sint Eustatius and Saba", iso2: "BQ" },
  { id: 156, name: "Netherlands", iso2: "NL" },
  { id: 157, name: "New Caledonia", iso2: "NC" },
  { id: 158, name: "New Zealand", iso2: "NZ" },
  { id: 159, name: "Nicaragua", iso2: "NI" },
  { id: 160, name: "Niger", iso2: "NE" },
  { id: 161, name: "Nigeria", iso2: "NG" },
  { id: 162, name: "Niue", iso2: "NU" },
  { id: 163, name: "Norfolk Island", iso2: "NF" },
  { id: 164, name: "Northern Mariana Islands", iso2: "MP" },
  { id: 165, name: "Norway", iso2: "NO" },
  { id: 166, name: "Oman", iso2: "OM" },
  { id: 167, name: "Pakistan", iso2: "PK" },
  { id: 168, name: "Palau", iso2: "PW" },
  { id: 169, name: "Palestinian Territory Occupied", iso2: "PS" },
  { id: 170, name: "Panama", iso2: "PA" },
  { id: 171, name: "Papua new Guinea", iso2: "PG" },
  { id: 172, name: "Paraguay", iso2: "PY" },
  { id: 173, name: "Peru", iso2: "PE" },
  { id: 174, name: "Philippines", iso2: "PH" },
  { id: 175, name: "Pitcairn Island", iso2: "PN" },
  { id: 176, name: "Poland", iso2: "PL" },
  { id: 177, name: "Portugal", iso2: "PT" },
  { id: 178, name: "Puerto Rico", iso2: "PR" },
  { id: 179, name: "Qatar", iso2: "QA" },
  { id: 180, name: "Reunion", iso2: "RE" },
  { id: 181, name: "Romania", iso2: "RO" },
  { id: 182, name: "Russia", iso2: "RU" },
  { id: 183, name: "Rwanda", iso2: "RW" },
  { id: 184, name: "Saint Helena", iso2: "SH" },
  { id: 185, name: "Saint Kitts And Nevis", iso2: "KN" },
  { id: 186, name: "Saint Lucia", iso2: "LC" },
  { id: 187, name: "Saint Pierre and Miquelon", iso2: "PM" },
  { id: 188, name: "Saint Vincent And The Grenadines", iso2: "VC" },
  { id: 189, name: "Saint-Barthelemy", iso2: "BL" },
  { id: 190, name: "Saint-Martin (French part)", iso2: "MF" },
  { id: 191, name: "Samoa", iso2: "WS" },
  { id: 192, name: "San Marino", iso2: "SM" },
  { id: 193, name: "Sao Tome and Principe", iso2: "ST" },
  { id: 194, name: "Saudi Arabia", iso2: "SA" },
  { id: 195, name: "Senegal", iso2: "SN" },
  { id: 196, name: "Serbia", iso2: "RS" },
  { id: 197, name: "Seychelles", iso2: "SC" },
  { id: 198, name: "Sierra Leone", iso2: "SL" },
  { id: 199, name: "Singapore", iso2: "SG" },
  { id: 200, name: "Slovakia", iso2: "SK" },
  { id: 201, name: "Slovenia", iso2: "SI" },
  { id: 202, name: "Solomon Islands", iso2: "SB" },
  { id: 203, name: "Somalia", iso2: "SO" },
  { id: 204, name: "South Africa", iso2: "ZA" },
  { id: 205, name: "South Georgia", iso2: "GS" },
  { id: 206, name: "South Sudan", iso2: "SS" },
  { id: 207, name: "Spain", iso2: "ES" },
  { id: 208, name: "Sri Lanka", iso2: "LK" },
  { id: 209, name: "Sudan", iso2: "SD" },
  { id: 210, name: "Suriname", iso2: "SR" },
  { id: 211, name: "Svalbard And Jan Mayen Islands", iso2: "SJ" },
  { id: 212, name: "Swaziland", iso2: "SZ" },
  { id: 213, name: "Sweden", iso2: "SE" },
  { id: 214, name: "Switzerland", iso2: "CH" },
  { id: 215, name: "Syria", iso2: "SY" },
  { id: 216, name: "Taiwan", iso2: "TW" },
  { id: 217, name: "Tajikistan", iso2: "TJ" },
  { id: 218, name: "Tanzania", iso2: "TZ" },
  { id: 219, name: "Thailand", iso2: "TH" },
  { id: 220, name: "Togo", iso2: "TG" },
  { id: 221, name: "Tokelau", iso2: "TK" },
  { id: 222, name: "Tonga", iso2: "TO" },
  { id: 223, name: "Trinidad And Tobago", iso2: "TT" },
  { id: 224, name: "Tunisia", iso2: "TN" },
  { id: 225, name: "Turkey", iso2: "TR" },
  { id: 226, name: "Turkmenistan", iso2: "TM" },
  { id: 227, name: "Turks And Caicos Islands", iso2: "TC" },
  { id: 228, name: "Tuvalu", iso2: "TV" },
  { id: 229, name: "Uganda", iso2: "UG" },
  { id: 230, name: "Ukraine", iso2: "UA" },
  { id: 231, name: "United Arab Emirates", iso2: "AE" },
  { id: 232, name: "United Kingdom", iso2: "GB" },
  { id: 233, name: "United States", iso2: "US" },
  { id: 234, name: "United States Minor Outlying Islands", iso2: "UM" },
  { id: 235, name: "Uruguay", iso2: "UY" },
  { id: 236, name: "Uzbekistan", iso2: "UZ" },
  { id: 237, name: "Vanuatu", iso2: "VU" },
  { id: 238, name: "Vatican City State (Holy See)", iso2: "VA" },
  { id: 239, name: "Venezuela", iso2: "VE" },
  { id: 240, name: "Vietnam", iso2: "VN" },
  { id: 241, name: "Virgin Islands (British)", iso2: "VG" },
  { id: 242, name: "Virgin Islands (US)", iso2: "VI" },
  { id: 243, name: "Wallis And Futuna Islands", iso2: "WF" },
  { id: 244, name: "Western Sahara", iso2: "EH" },
  { id: 245, name: "Yemen", iso2: "YE" },
  { id: 246, name: "Zambia", iso2: "ZM" },
  { id: 247, name: "Zimbabwe", iso2: "ZW" },
  { id: 248, name: "Kosovo", iso2: "XK" },
  { id: 249, name: "Cura\u00e7ao", iso2: "CW" },
  { id: 250, name: "Sint Maarten (Dutch part)", iso2: "SX" },
];

export const propertyTypes = [
  {
    id: 1,
    name: "Casa",
    icon: HouseIcon,
    lbl: "la casa",
    hasLevels: true,
    hasFloornumber: false,
  },
  {
    id: 2,
    name: "Apartamento",
    icon: ApartmentIcon,
    lbl: "el apartamento",
    hasLevels: true,
    hasFloornumber: true,
  },
  {
    id: 3,
    name: "Cabaña",
    icon: BungalowIcon,
    lbl: "la cabaña",
    hasLevels: true,
    hasFloornumber: false,
  },
  {
    id: 4,
    name: "Rancho de playa",
    icon: RanchoPlayaIcon,
    lbl: "el rancho de playa",
    hasLevels: true,
    hasFloornumber: false,
  },
  {
    id: 5,
    name: "Local comercial",
    icon: NegocioIcon,
    lbl: "el local comercial",
    hasLevels: true,
    hasFloornumber: true,
  },
  {
    id: 6,
    name: "Habitación o estudio",
    icon: LivingIcon,
    lbl: "la habitación o estudio",
    hasLevels: true,
    hasFloornumber: true,
  },
  {
    id: 7,
    name: "Terreno",
    icon: TerrainIcon,
    lbl: "el terreno",
    hasLevels: false,
    hasFloornumber: false,
  },
  {
    id: 8,
    name: "Casa en el lago",
    icon: LakeIcon,
    lbl: "la casa",
    hasLevels: true,
    hasFloornumber: false,
  },
  {
    id: 9,
    name: "Apartamento de playa",
    icon: RanchoPlayaIcon,
    lbl: "el rancho de playa",
    hasLevels: false,
    hasFloornumber: true,
  },
  {
    id: 10,
    name: "Habitación de hotel",
    icon: RanchoPlayaIcon,
    lbl: "el rancho de playa",
    hasLevels: false,
    hasFloornumber: true,
  },
];

// TODO iconos pendientes
export const propertyAmenities = [
  {
    id: 1,
    name: "Área de juegos para niños",
    icon: ChildCareIcon,
    lbl: "la casa",
  },
  {
    id: 2,
    name: "Terraza o balcón",
    icon: BalconyIcon,
    lbl: "el apartamento",
  },
  {
    id: 3,
    name: "Salón de eventos",
    icon: MeetingRoomIcon,
    lbl: "la cabaña",
  },
  { id: 4, name: "Área de trabajo", icon: DeskIcon, lbl: "la cabaña" },
  { id: 5, name: "Gimnasio", icon: FitnessCenterIcon, lbl: "la cabaña" },
  { id: 6, name: "Barbacoa", icon: OutdoorGrillIcon, lbl: "la cabaña" },
  { id: 7, name: "Acceso a la playa", icon: BeachAccessIcon, lbl: "la cabaña" },
  { id: 8, name: "Acceso al lago", icon: KayakingIcon, lbl: "la cabaña" },
  { id: 9, name: "Área verde", icon: ForestIcon, lbl: "la cabaña" },
  { id: 10, name: "Áreas pet friendly", icon: PetsIcon, lbl: "la cabaña" },
  { id: 11, name: "Seguridad 24/7", icon: LocalPoliceIcon, lbl: "la cabaña" },
  { id: 12, name: "Elevador", icon: ElevatorIcon, lbl: "la cabaña" },
  { id: 13, name: "Rooftop", icon: RoofingIcon, lbl: "la cabaña" },
  { id: 14, name: "Piscina", icon: PoolIcon, lbl: "la cabaña" },
  { id: 15, name: "Jacuzzi", icon: HotTubIcon, lbl: "la cabaña" },
];

// TODO integración e iconos pendientes
export const includedWithRent = [
  {
    id: 1,
    name: "Línea blanca",
    icon: LocalLaundryServiceIcon,
    lbl: "la casa",
  },
  { id: 2, name: "Básicos de cocina", icon: FlatwareIcon, lbl: "la casa" },
  { id: 3, name: "Totalmente amueblado", icon: ChairIcon, lbl: "la casa" },
  {
    id: 4,
    name: "Cocina y electrodomésticos",
    icon: BlenderIcon,
    lbl: "la casa",
  },
  { id: 5, name: "Internet", icon: WifiIcon, lbl: "la casa" },
  { id: 6, name: "Energía eléctrica", icon: BoltIcon, lbl: "la casa" },
  { id: 7, name: "Servicio de agua", icon: WaterDamageIcon, lbl: "la casa" },
];
// TODO integración e iconos pendientes
export const includedOnPayment = [
  { id: 1, name: "Cuota de mantenimiento", icon: HouseIcon, lbl: "la casa" },
  { id: 2, name: "Cuota de seguridad", icon: HouseIcon, lbl: "la casa" },
  { id: 3, name: "Impuestos municipales", icon: HouseIcon, lbl: "la casa" },
];

export const propertyStatus = [
  // { id: 1, name: "Alquilado - No disponible", available:false  },
  // { id: 2, name: "Alquilado - Disponible", available:true},
  { id: 1, name: "Disponible", available: true },
  { id: 2, name: "No disponible por ahora", available: false },
  { id: 3, name: "Alquilado", available: false },
];

export const Item = styled(Paper)(({ theme, selected }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "left",
  color: selected ? theme.palette.primary.main : theme.palette.text.secondary,
  border: `2px solid ${
    selected ? theme.palette.primary.main : theme.palette.divider
  }`,
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

export const currencyList = [
  { id: 1, name: "Dólares Americanos", icon: HouseIcon, lbl: "$" },
  { id: 2, name: "Euros", icon: HouseIcon, lbl: "€" },
  { id: 3, name: "Colones costarricenses", icon: HouseIcon, lbl: "₡" },
  { id: 4, name: "Lempiras", icon: HouseIcon, lbl: "L" },
  { id: 5, name: "Quetzales", icon: HouseIcon, lbl: "Q" },
  { id: 6, name: "Peso Mexicano", icon: HouseIcon, lbl: "$" },
  { id: 7, name: "Real brasileño", icon: HouseIcon, lbl: "R$" },
  { id: 8, name: "Peso argentino", icon: HouseIcon, lbl: "$a" },
  { id: 9, name: "Peso colombiano", icon: HouseIcon, lbl: "$" },
  { id: 10, name: "Peso uruguayo", icon: HouseIcon, lbl: "$" },
  { id: 11, name: "Balboa", icon: HouseIcon, lbl: "B/." },
  // { id: 11, name: "Balboa 1", icon: HouseIcon, lbl: "B/." },
  // { id: 12, name: "Balboa 2", icon: HouseIcon, lbl: "B/." },
];

export const paymentMethodsList = [
  {
    id: 1,
    name: "NexPay",
    description: "Recibe pagos con tarjetas de crédito y débito",
    subtitle:
      "Recibe pagos con tarjetas de crédito y débito. \n(Consulta comisiones por transacción.)",
    needs:""    
  },
  // {
  //   id: 2,
  //   name: "NexCash - NexAI Wallet",
  //   description: "Recibe pagos con billetera NexAI",
  //   subtitle: "Tus clientes pueden utilizar su saldo en NexAI para pagar en tu comercio.",
  //   needs:""
  // },
  {
    id: 2,
    name: "Efectivo (contra entrega)",
    description: "Pagos en efectivo gestionados por tu cuenta",
    subtitle: "Recibe pagos en efectivo, realiza el cuadre contra entrega.",
    needs:""
  },
  {
    id: 3,
    name: "Tarjeta",
    description: "Recibe pagos con tarjeta (5% + $0.30)",
    subtitle: "Recibe pagos con tarjeta (5% + $0.30).",
    needs:""
  },
  {
    id: 4,
    name: "Depósito bancario (Transferencia)",
    description: "Pagos en cuentas bancarias",
    subtitle: "Recibe pagos en tu cuenta de banco directamente, puedes utilizar cualquier código QR o link de tu banco.",
    needs:""
  },
  {
    id: 5,
    name: "Paypal",
    description: "Pagos por medio de links de pago de PayPal",
    subtitle: "Utiliza tu link de Paypal para recibir pagos.",
    needs:""
  },
  {
    id: 6,
    name: "Bitcoin",
    description: "Pagos por medio de billeteras Bitcoin (Lightning)",
    subtitle: "Utiliza tu wallet en Lightning Network.",
    needs:""
  },
  {
    id: 7,
    name: "Crédito",
    description: "Crédito gestionado por tu cuenta",
    subtitle: "Esta opción estará disponible únicamente en el punto de venta físico.",
    needs:""
  },
];

export const sellTaxesList = [
  {
    id: 1,
    name: "IVA- Impuesto al valor agregado (13%)",
    subtitle: "IVA- Impuesto al valor agregado (13%)",
    taxType: "percent",
    taxValue: 13,
  },
  {
    id: 2,
    name: "Impuesto ad- valorem por diferencial de precios de bebidas alcohólicas (8%)",
    subtitle: "Impuesto ad- valorem por diferencial de precios de bebidas alcohólicas (8%)",
    taxType: "percent",
    taxValue: 8,
  },
  {
    id: 3,
    name: "Impuesto ad- valorem por diferencial de precios al tabaco cigarrillos (39%)",
    subtitle: "Impuesto ad- valorem por diferencial de precios al tabaco cigarrillos (39%)",
    taxType: "percent",
    taxValue: 39,
  },
  {
    id: 4,
    name: "Impuesto ad- valorem por diferencial de precios al tabaco cigarros (100%)",
    subtitle: "Impuesto ad- valorem por diferencial de precios al tabaco cigarros (100%)",
    taxType: "percent",
    taxValue: 100,
  },
  {
    id: 5,
    name: "Turismo por alojamiento (5%)",
    subtitle: "Turismo por alojamiento (5%)",
    taxType: "percent",
    taxValue: 5,
  },
  {
    id: 6,
    name: "Turismo: salida del país por vía aérea $7.00",
    subtitle: "Turismo: salida del país por vía aérea $7.00",
    taxType: "fixed",
    taxValue: 7,
  },
  {
    id: 7,
    name: "ISR - Renta (10%)",
    subtitle: "ISR - Renta (10%)",
    taxType: "percent",
    taxValue: 10,
  },
  {
    id: 8,
    name: "Propina (10%)",
    subtitle: "Propina (10%)",
    taxType: "percent",
    taxValue: 10,
  },
  
]

export const deliveryMethodsList = [
  { id: 1, name: "Domicilio", description: "",subtitle: "Envía tus productos con delivery propio o de terceros.", },
  { id: 2, name: "Retiro en local o bodega", description: "",subtitle: "Esta opción estará disponible en tu horario de local.", },
  { id: 3, name: "Entrega en punto de encuentro ", description: "",subtitle: "Realiza entregas en puntos de conveniencia, centros comerciales, etc.", },
];

export const dummyProductList = [
  {
    id:1,
    title:"Porción de tortilla de Patata",
    qty:1,
    prc:5.99,    
    nota: ""    
  },
  {
    id:2,
    title:"Pupusas revueltas",
    qty:4,
    prc:0.75,    
    nota: ""
  },
  {
    id:3,
    title:"Refresco de ensalada",
    qty:3,
    prc:0.45,
    nota: "Sin hielo, cliente dice que se le quiere quitar para que no se atragante porque es alergico"
  },
  {
    id:4,
    title:"Orden de tacos de pescado",
    qty:1,
    prc:4.99,
    nota: "Sin cebolla, cliente dice que se le debe quitar"
  },
]

export default config;
