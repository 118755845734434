import { createSlice } from "@reduxjs/toolkit";

// project imports
import { dispatch } from "../index";
// import axios from "utils/axios";
import axios from "axios";
import { API_URL } from "config";
// initial state
const initialState = {
  selectedItem: ["dashboard"],
  selectedID: null,
  drawerOpen: false,
  error: null,
  menuDashboard: {},
};

// ==============================|| SLICE - MENU ||============================== //

const menu = createSlice({
  name: "menu",
  initialState,
  reducers: {
    activeItem(state, action) {
      state.selectedItem = action.payload;
    },

    activeID(state, action) {
      state.selectedID = action.payload;
    },

    openDrawer(state, action) {
      state.drawerOpen = action.payload;
    },

    // has error
    hasError(state, action) {
      state.error = action.payload;
    },

    // get dashboard menu
    getMenuListSuccess(state, action) {
      state.menuDashboard = action.payload;
    },

    getMenuListSuccess2(state, action) {
      state.menuDashboard2 = action.payload;
    },

    getMenuListSuccess3(state, action) {
      state.menuDashboard3 = action.payload;
    },

    getMenuListSuccess4(state, action) {
      state.menuDashboard4 = action.payload;
    },

    getMenuListSuccess5(state, action) {
      state.menuDashboard5 = action.payload;
    },

    getMenuListSuccess6(state, action) {
      state.menuDashboard6 = action.payload;
    },

    getMenuListSuccess7(state, action) {
      state.menuDashboard7 = action.payload;
    },

    getMenuListSuccess8(state, action) {
      state.menuDashboard8 = action.payload;
    },

    getMenuListSuccess9(state, action) {
      state.menuDashboard9 = action.payload;
    },
    getMenuListSuccess10(state, action) {
      state.menuDashboard10 = action.payload;
    },
  },
});

export default menu.reducer;

export const { activeItem, openDrawer, activeID } = menu.actions;
export function getDashboard(bu) {
  return async () => {
    try {
      let ur = API_URL + "dash/" + bu;
      const response = await axios.get(ur);
      //   dispatch(menu.actions.getMenuListSuccess(response.data.another));
      // console.log(response.data.application);
      try {
        dispatch(menu.actions.getMenuListSuccess(response.data.dashboard));
      } catch (error) {}
      try {
        dispatch(menu.actions.getMenuListSuccess2(response.data.dashboard1));
      } catch (error) {}
      try {
        dispatch(menu.actions.getMenuListSuccess3(response.data.dashboard2));
      } catch (error) {}
      try {
        dispatch(menu.actions.getMenuListSuccess4(response.data.dashboard3));
      } catch (error) {}
      try {
        dispatch(menu.actions.getMenuListSuccess5(response.data.dashboard4));
      } catch (error) {}
      try {
        dispatch(menu.actions.getMenuListSuccess6(response.data.dashboard5));
      } catch (error) {}
      try {
        dispatch(menu.actions.getMenuListSuccess7(response.data.dashboard6));
      } catch (error) {}
      try {
        dispatch(menu.actions.getMenuListSuccess8(response.data.dashboard7));
      } catch (error) {}
      try {
        dispatch(menu.actions.getMenuListSuccess9(response.data.dashboard8));
      } catch (error) {}
      try {
        dispatch(menu.actions.getMenuListSuccess10(response.data.dashboard10));
      } catch (error) {}
      
    } catch (error) {
      dispatch(menu.actions.hasError(error));
    }
  };
}
