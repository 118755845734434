// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconChartArcs, IconClipboardList, IconChartInfographic, IconUserCircle, IconHelp, IconBuildingStore, IconHexagonLetterI, IconGraph,IconBox,IconBuilding } from '@tabler/icons';

// constant
const icons = {
    IconChartArcs,
    IconClipboardList,
    IconChartInfographic,
    IconUserCircle,
    IconHelp,
    IconBuildingStore,
    IconHexagonLetterI,
    IconGraph,
    IconBox,
    IconBuilding
};

// ==============================|| WIDGET MENU ITEMS ||============================== //

const myAccount = {
    id: 'my_account',
    title: <FormattedMessage id="my_account" />,
    icon: icons.IconChartArcs,
    type: 'group',
    children: [

        {
            id: 'business_information',
            title: <FormattedMessage id="business_information" />,
            type: 'item',
            url: '/about_business', 
            icon: icons.IconBuilding
        },
        
        // {
        //     id: 'user_account',
        //     title: <FormattedMessage id="user_account" />,
        //     type: 'item',
        //     url: '/my_account',
        //     icon: icons.IconGraph
        // },
        {
            id: 'support',
            title: <FormattedMessage id="support" />,
            type: 'item',
            url: '/support', 
            icon: icons.IconHelp
        },                
        // {
        //     id: 'prds',
        //     title: <FormattedMessage id="prds" />,
        //     type: 'item',
        //     url: '/prds', 
        //     icon: icons.IconBox
        // },                         
                
        // {
        //     id: 'business_account',
        //     title: <FormattedMessage id="business_account" />,
        //     type: 'item',
        //     url: '/dashboard/my_business',
        //     icon: icons.IconBuildingStore
        // }
    ]
};

export default myAccount;
