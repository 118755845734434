import { useEffect, useState, useContext } from "react";

// routing
import Routes from "routes";

// project imports
import Locales from "ui-component/Locales";
import NavigationScroll from "layout/NavigationScroll";
import RTLLayout from "ui-component/RTLLayout";
import Snackbar from "ui-component/extended/Snackbar";
import Loader from "ui-component/Loader";

import ThemeCustomization from "themes";
import { dispatch } from "store";
import { getDashboard } from "store/slices/menu";
import Hotjar from "@hotjar/browser";
// import * as amplitude from "@amplitude/analytics-browser";
import { FIREBASE_API } from "config";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

// auth provider
import { FirebaseProvider as AuthProvider } from "contexts/FirebaseContext";

// ==============================|| APP ||============================== //
const siteId = 3694029;
const hotjarVersion = 6;
// amplitude.init("55c1f041e1a95967cd088e68dd2a53a2");

Hotjar.init(siteId, hotjarVersion);

const getBUFromFRTDB = (userId) => {
  if (!firebase.apps.length) {
    firebase.initializeApp(FIREBASE_API);
  }

  //   const userId = firebase.auth().currentUser.uid;
  const cartNodeRef = firebase.database().ref(`lastbus/${userId}/lastbu`);

  return cartNodeRef
    .once("value")
    .then((snapshot) => {
      const lastbuValue = snapshot.val();
      // console.log(`Valor obtenido de lastbus/${userId}/lastbu:`, lastbuValue);
      return lastbuValue;
    })
    .catch((error) => {
      // console.error("Error al obtener el valor desde Firebase:", error);
      return null;
    });
};

const App = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const lastbuValue = await getBUFromFRTDB(user.uid);
          dispatch(getDashboard(lastbuValue)).then(() => {
            setLoading(true);
          });
        } catch (error) {
          // console.error("Error obtaining lastbu value:", error);
          setLoading(true);
        }
      } else {
        setLoading(true);
        // console.log("There is no logged in user");
      }
    });
    return () => unsubscribe();
  }, []);

  if (!loading) return <Loader />;

  return (
    <ThemeCustomization>
      <RTLLayout>
        <Locales>
          <NavigationScroll>
            <AuthProvider>
              <>
                <Routes />
                <Snackbar />
              </>
            </AuthProvider>
          </NavigationScroll>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  );
};

export default App;
