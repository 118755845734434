import { memo, useEffect } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Typography, useMediaQuery } from "@mui/material";

// project imports
import menuItem from "menu-items";
import NavGroup from "./NavGroup";
import useConfig from "hooks/useConfig";

import { DashboardMenu } from "menu-items/dashboard";
import { DashboardMenu2 } from "menu-items/dashboard-sec";
import { DashboardMenu3 } from "menu-items/dashboard-sec3";
import { DashboardMenu4 } from "menu-items/dashboard-sec4";
import { DashboardMenu5 } from "menu-items/dashboard-sec5";
import { DashboardMenu6 } from "menu-items/dashboard-sec6";
import { DashboardMenu7 } from "menu-items/dashboard-sec7";
import { DashboardMenu8 } from "menu-items/dashboard-sec8";
import { DashboardMenu9 } from "menu-items/dashboard-sec9";
import { DashboardMenu10 } from "menu-items/dashboard-sec10";

import LAYOUT_CONST from "constant";
import { HORIZONTAL_MAX_ITEM } from "config";

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const theme = useTheme();
  const { layout } = useConfig();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  let getDash, getDash2, getDash3, getDash4, getDash5, getDash6, getDash7, getDash8, getDash9, getDash10;

  try {
    getDash = DashboardMenu();
  } catch (error) {}
  try {
    getDash2 = DashboardMenu2();
  } catch (error) {}
  try {
    getDash3 = DashboardMenu3();
  } catch (error) {}
  try {
    getDash4 = DashboardMenu4();
  } catch (error) {}
  try {
    getDash5 = DashboardMenu5();
  } catch (error) {}
  try {
    getDash6 = DashboardMenu6();
  } catch (error) {}
  try {
    getDash7 = DashboardMenu7();
  } catch (error) {}
  try {
    getDash8 = DashboardMenu8();
  } catch (error) {}
  try {
    getDash9 = DashboardMenu9();
  } catch (error) {}
  try {
    getDash10 = DashboardMenu10();
  } catch (error) {}

  const handlerMenuItem = () => {
    const isFound = menuItem.items.some((element) => {
      if (element.id === "dashboard") {
        return true;
      }
      return false;
    });

    try {
        if (getDash10?.id !== undefined && !isFound) {
          menuItem.items.splice(0, 0, getDash10);
        }
      } catch (error) {}
      
      try {
        if (getDash9?.id !== undefined && !isFound) {
          menuItem.items.splice(0, 0, getDash9);
        }
      } catch (error) {}
      
      try {
        if (getDash8?.id !== undefined && !isFound) {
          menuItem.items.splice(0, 0, getDash8);
        }
      } catch (error) {}
      
      try {
        if (getDash7?.id !== undefined && !isFound) {
          menuItem.items.splice(0, 0, getDash7);
        }
      } catch (error) {}
      
      try {
        if (getDash6?.id !== undefined && !isFound) {
          menuItem.items.splice(0, 0, getDash6);
        }
      } catch (error) {}
      
      try {
        if (getDash5?.id !== undefined && !isFound) {
          menuItem.items.splice(0, 0, getDash5);
        }
      } catch (error) {}
      
      try {
        if (getDash4?.id !== undefined && !isFound) {
          menuItem.items.splice(0, 0, getDash4);
        }
      } catch (error) {}
      
      try {
        if (getDash3?.id !== undefined && !isFound) {
          menuItem.items.splice(0, 0, getDash3);
        }
      } catch (error) {}
      
      try {
        if (getDash2?.id !== undefined && !isFound) {
          menuItem.items.splice(0, 0, getDash2);
        }
      } catch (error) {}
      
      try {
        if (getDash?.id !== undefined && !isFound) {
          menuItem.items.splice(0, 0, getDash);
        }
      } catch (error) {}

      
    // try {
    //   if (getDash?.id !== undefined && !isFound) {
    //     menuItem.items.splice(0, 0, getDash);
    //   }
    // } catch (error) {}
    // try {
    //   if (getDash2?.id !== undefined && !isFound) {
    //     menuItem.items.splice(0, 0, getDash2);
    //   }
    // } catch (error) {}

    // try {
    //   if (getDash3?.id !== undefined && !isFound) {
    //     menuItem.items.splice(0, 0, getDash3);
    //   }
    // } catch (error) {}

    // try {
    //   if (getDash4?.id !== undefined && !isFound) {
    //     menuItem.items.splice(0, 0, getDash4);
    //   }
    // } catch (error) {}

    // try {
    //   if (getDash5?.id !== undefined && !isFound) {
    //     menuItem.items.splice(0, 0, getDash5);
    //   }
    // } catch (error) {}

    // try {
    //   if (getDash6?.id !== undefined && !isFound) {
    //     menuItem.items.splice(0, 0, getDash6);
    //   }
    // } catch (error) {}

    // try {
    //   if (getDash7?.id !== undefined && !isFound) {
    //     menuItem.items.splice(0, 0, getDash7);
    //   }
    // } catch (error) {}

    // try {
    //   if (getDash8?.id !== undefined && !isFound) {
    //     menuItem.items.splice(0, 0, getDash8);
    //   }
    // } catch (error) {}

    // try {
    //   if (getDash9?.id !== undefined && !isFound) {
    //     menuItem.items.splice(0, 0, getDash9);
    //   }
    // } catch (error) {}
    // try {
    //   if (getDash10?.id !== undefined && !isFound) {
    //     menuItem.items.splice(0, 0, getDash10);
    //   }
    // } catch (error) {}
  };

  useEffect(() => {
    handlerMenuItem();
    // eslint-disable-next-line
  }, []);

  // last menu-item to show in the horizontal menu bar
  const lastItem =
    layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd
      ? HORIZONTAL_MAX_ITEM
      : null;

  let lastItemIndex = menuItem.items.length - 1;
  let remItems = [];
  let lastItemId;

  if (lastItem && lastItem < menuItem.items.length) {
    lastItemId = menuItem.items[lastItem - 1].id;
    lastItemIndex = lastItem - 1;
    remItems = menuItem.items
      .slice(lastItem - 1, menuItem.items.length)
      .map((item) => ({
        title: item.title,
        elements: item.children,
      }));
  }

  const navItems = menuItem.items.slice(0, lastItemIndex + 1).map((item) => {
    switch (item.type) {
      case "group":
        return (
          <NavGroup
            key={item.id}
            item={item}
            lastItem={lastItem}
            remItems={remItems}
            lastItemId={lastItemId}
          />
        );
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default memo(MenuList);
