// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
// import axios from 'utils/axios';
import axios from "axios";

import { dispatch } from '../index';
import { FIREBASE_API } from 'config';
import { API_URL } from 'config';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    businessName: "",
    fiscalName: "",
    businessSize: "",
    businessType: "",
    ref: "",
    areaCode:"",
    uid:"",
    sessionToken:"",
    otp:"",
    isLoading: false,
    country:"",
    firstNames:"",
    lastNames:""
};

const slice = createSlice({
    name: 'on-boarding',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },        
        // SET BUSINESSACCOUNTDATA    
        setBusinessAccountData(state, action) {
            // console.log(action);
            // console.log("setting up:",action.payload.payload.businessName)
            // state.businessName=state.businessName.concat(action.payload.payload.businessName);
            // state.businessSize=state.businessSize.concat(action.payload.payload.businessSize);
            // state.businessType=state.businessType.concat(action.payload.payload.businessType);
            // state.fiscalName=state.fiscalName.concat(action.payload.payload.fiscalName);
            // state.uid = state.uid.concat(action.payload.payload.uid);
            // state.ref=state.ref.concat(action.payload.payload.referralCode);



            state.businessName=action.payload.payload.businessName;
            state.businessSize=action.payload.payload.businessSize;
            state.businessType=action.payload.payload.businessType;
            state.fiscalName=action.payload.payload.fiscalName;
            state.uid = action.payload.payload.uid;
            state.ref=action.payload.payload.referralCode;
            state.firstNames=action.payload.payload.firstNames;
            state.lastNames=action.payload.payload.lastNames;


            // state.sessionToken = state.sessionToken.concat(action.payload.payload.sessionToken);
            state.sessionToken = action.payload.payload.sessionToken;

            // return Object.assign({}, state, {                
            //     businessName: state.businessName.concat(action.payload.payload.businessName),
            //     businessSize: state.businessSize.concat(action.payload.payload.businessSize),
            //     businessType: state.businessType.concat(action.payload.payload.businessType),
            //     fiscalName: state.fiscalName.concat(action.payload.payload.fiscalName),
            //     ref: state.ref.concat(action.payload.ref)
            //   });            
        },
        setContactPhone(state,action){
            console.log(action);
            console.log(state);
            // console.log(action.payload.payload.areaCode);
            // console.log(initialState.businessName);
            // return Object.assign({}, state, {
            //     areaCode: state.areaCode.concat(action.payload.areaCode)
            //   }); 

            // state.areaCode=state.areaCode.concat(action.payload.payload.areaCode);
            state.areaCode=action.payload.payload.areaCode;
            state.firstNames=action.payload.payload.vName;
            state.lastNames=action.payload.payload.vLastName;
            const response = axios.post(API_URL+"users/verifyStart", {
                areaCode:state.areaCode,
                uid:state.sessionToken,
                businessName:state.businessName,
                vName: state.firstNames,
                vLastName: state.lastNames,
            });
            // console.log("sending to server",state.businessName);

        },     
        // tryToVerifyOTP(state,action){
            
        //     state.otp = state.otp.concat(action.payload.payload.otp);
        //     console.log("sending data otp",state.otp);
        //     const response = axios.post(API_URL+"users/verify", {
        //         otp:state.otp,
        //         uid:state.sessionToken,
        //         b:state.businessName,
        //         n:state.fiscalName,
        //         bs: state.businessType,
        //         bsc: state.businessSize,
        //         p: state.ref,
        //         areaCode: state.areaCode
        //     });
        //     console.log(response);
        //     return response;
        // }
        tryToVerifyOTP(state, action) {
            state.otp = state.otp = action.payload.payload.otp;
            console.log("sending data otp", state.otp);
          },

    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function addBusinessData(data,action) {
    return async () => {
        try {
            // const response = await axios.post('/api/calendar/events/new', event);
            var rpt = dispatch(slice.actions.setBusinessAccountData(data,action));
            // console.log("estado:",rpt);
            // console.log(state);
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function addContactPhone(data,action){
    return async () => {
        try {
            // const response = await axios.post('/api/calendar/events/new', event);
            var rpt = dispatch(slice.actions.setContactPhone(data,action));
            // console.log("estadoaddcontactphone",rpt);
            // console.log(state);
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error))
        }
    };
}
export function tryToVerifyOTP(data, action) {
    return async (dispatch, getState) => {
      try {
        var rpts = dispatch(slice.actions.tryToVerifyOTP(data,action));
        console.log("starting petition");
        const state = getState().onboarding;
        console.log(state);
        let cCode = localStorage.getItem("ccd0","");
        const response = await axios.post(API_URL + "users/verify", {
          otp: state.otp,
          uid: state.sessionToken,
          b: state.businessName,
          n: state.fiscalName,
          bs: state.businessType,
          bsc: state.businessSize,
          p: state.ref,
          areaCode: state.areaCode,
          country: cCode,          
        });
        // console.log(response);
        // var isTrueSet = (response.data.toLowerCase() === 'True');
        let myBool = (response.data === 'OK');
        console.log(response.data);
        console.log(myBool);
        return myBool;
      } catch (error) {
        console.log(error);
        dispatch(slice.actions.hasError(error));
      }
    };
  }