import PropTypes from "prop-types";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// project imports
import useAuth from "hooks/useAuth";
import { DASHBOARD_PATH } from "config";
import { DASHBOARD_PATH_INITIATED } from "config";

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }) => {
  const { isLoggedIn, user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      // console.log(typeof(user.businessData));
      // console.log(Array.isArray(user.businessData));
      // console.log(Object.keys(user.businessData).length);
      var cntObject = 0;
      try {
        cntObject = Object.keys(user.businessData).length;
      } catch (error) {}

      if (cntObject > 0) {
        navigate(DASHBOARD_PATH_INITIATED, { replace: true });
      } else {
        navigate(DASHBOARD_PATH, { replace: true });
      }
    }
  }, [isLoggedIn, navigate]);

  return children;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
