import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { memo } from "react";
import Lottie from "react-lottie-player";
import axios from "axios";
import { API_URL } from "config";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import { FIREBASE_API } from "config";
// material-ui
import { styled, useTheme } from "@mui/material/styles";
import {
  Avatar,
  Card,
  CardContent,
  Link,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  linearProgressClasses,
  Box,
  ListItemButton,
  Button,
  ListItemIcon,
  Popover,
  Divider,
  MenuItem,
} from "@mui/material";

// assets
// import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SavingsIcon from "@mui/icons-material/Savings";
import Storefront from "@mui/icons-material/Storefront";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";
import useAuth from "hooks/useAuth";
import lottieJson from "assets/images/animations/storefront.json";
// styles
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 30,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.dark.light : "#fff",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.primary.dark
        : theme.palette.primary.main,
  },
}));

const CardStyle = styled(Card)(({ theme }) => ({
  background:
    theme.palette.mode === "dark"
      ? theme.palette.dark.main
      : theme.palette.primary.light,
  marginBottom: "22px",
  overflow: "hidden",
  position: "relative",
  //   borderStyle: "solid",
  //   borderWidth: "0.5px",
  //   borderColor: "gray",
}));

const styles = {
  cardcontent: {
    padding: 0,
    "&:last-child": {
      paddingBottom: 0,
    },
  },
};

const CardStyleSecondary = styled(Card)(({ theme }) => ({
  background:
    theme.palette.mode === "dark"
      ? theme.palette.dark.main
      : theme.palette.primary.light,
  marginBottom: "22px",
  overflow: "hidden",
  position: "relative",
  "&:after": {
    content: '""',
    position: "absolute",
    width: "157px",
    height: "157px",
    background:
      theme.palette.mode === "dark"
        ? theme.palette.dark.dark
        : theme.palette.primary[200],
    borderRadius: "50%",
    top: "-105px",
    right: "-96px",
  },
}));

// ==============================|| PROGRESS BAR WITH LABEL ||============================== //

function LinearProgressWithLabel({ value, ...others }) {
  const theme = useTheme();

  return (
    <Grid container direction="column" spacing={1} sx={{ mt: 1.5 }}>
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography
              variant="h6"
              sx={{
                color:
                  theme.palette.mode === "dark"
                    ? theme.palette.dark.light
                    : theme.palette.primary[800],
              }}
            >
              Saldo de referidos
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" color="inherit">{`$${Math.round(
              value
            )}`}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <BorderLinearProgress variant="determinate" value={value} {...others} />
      </Grid>
    </Grid>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number,
};

// ==============================|| SIDEBAR - MENU CARD ||============================== //

const MenuCard = () => {
  const theme = useTheme();
  const { user } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [activeBusiness, setActiveBusiness] = useState([]);
  

  useEffect(() => {
    try {
      // console.log("MENU CARD OBSERVER>");
      // console.log(user);
      // console.info(user.lastbudata);
      // console.log("MENU CARD OBSERVER<");
      setActiveBusiness(user.lastbudata);
      if (user.lastbudata && user.lastbudata.id) {
        localStorage.setItem("_390", user.lastbudata.id);
      }
    } catch (s) {
      // console.log("ERROR IN USER DATA FETCHING FROM LOCAL STORAGE");
    }
  }, [user]);

  const saveBUtoFRTDB = (bidv) => {
    if (!firebase.apps.length) {
      firebase.initializeApp(FIREBASE_API);
    }
    let userid = firebase.auth().currentUser.uid;
    const cartNodeRef = firebase.database().ref(`lastbus/${userid}`);
    cartNodeRef
      .set({
        lastbu: bidv.id,
      })
      .then(() => {
        // console.log(`Nodo creado en lastbus/${userid}/${bidv}`);
      })
      .catch((error) => {
        // console.error("Error al crear el nodo en Firebase:", error);
      });
  };
  const saveBUToAPI = (bidv) => {
    saveBUtoFRTDB(bidv);
    // console.log("SETTING LASTBU");
    setActiveBusiness(bidv);
    const url = API_URL + "lastbusiness";
    const payload = {
      uid: user.sessionToken,
      bid: bidv.id,
    };
    const headers = {
      headers: {
        Authorization: "Bearer " + user.sessionToken,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post(url, payload, headers)
        .then((response) => {
          resolve(response.data.id);
          window.location.reload();
        })
        .catch((error) => {
          console.error(error);
          reject(false);
        });
    });
  };

  //  console.log(user.sessionToken);
  // const report = await saveReportToAPI(values);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsPopoverOpen(!isPopoverOpen);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsPopoverOpen(false);
  };

  // const retrieveActiveCommerce = () => {
  //   setActiveCommerce(JSON.parse(localStorage.getItem("ac")));
  // };
  const BusinessCardItem = ({ option }) => {
    // console.log(option);
    return (
      <ListItem
        alignItems="flex-start"
        disableGutters        
        sx={{ p: 0, maxWidth: 300, width: 226, cursor: "pointer" }}
        // onClick={(o) => setActiveBusiness(option)}
        onClick={(o) => saveBUToAPI(option)}
      >
        <ListItemAvatar sx={{ mt: 1, ml: 1 }}>
          <Avatar
            sx={{ bgcolor: "white" }}
            src={option.business_image}
          ></Avatar>
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{
            variant: "caption",
            style: {
              fontWeight: "bold",
              textOverflow: "ellipsis",
              whiteSpace: "normal",
              overflow: "hidden",
              width: "90%",
              maxHeight: 20,
              height: 20,
              marginTop: 2,
              marginRight: 10,
            },
          }}
          primary={option.business_name}
          secondary={
            <Box>
              <Typography variant="caption">{option.role[0]?.name}</Typography>
            </Box>
          }
          secondaryTypographyProps={{
            variant: "caption",
          }}
        />
      </ListItem>
    );
  };

  return (
    <div style={{ marginTop: '9px' }}>
      {activeBusiness && activeBusiness.role && activeBusiness.role[0] ? (
        <CardStyle variant="outlined">
          <CardContent
            sx={{ p: 1, cursor: "pointer", "&:last-child": { pb: 1 } }}
            onClick={handleClick}
          >
            <List sx={{ p: 0, m: 0 }}>
              <ListItem alignItems="flex-start" disableGutters sx={{ p: 0 }}>
                <ListItemAvatar sx={{ mt: 1, ml: 1 }}>
                  <Avatar
                    sx={{ bgcolor: "white" }}
                    src={activeBusiness.business_image}
                  >
                    {/* <Storefront fontSize="inherit" /> */}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  // sx={{ mt: 0 }}
                  primaryTypographyProps={{
                    variant: "caption",
                    style: {
                      fontWeight: "bold",
                      textOverflow: "ellipsis",
                      whiteSpace: "normal",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "100%",
                      maxHeight: 40,
                      height: "auto",
                      verticalAlign: "center",
                      display: "flex",
                      justifyContent: "left",
                      // alignItems:'center'
                    },
                  }}
                  primary={activeBusiness.business_name}
                  secondary={
                    <Box>
                      <Typography variant="caption">
                        {activeBusiness?.role[0]?.name}
                      </Typography>
                    </Box>
                  }
                  secondaryTypographyProps={{
                    variant: "caption",
                    style: {
                      textOverflow: "ellipsis",
                      whiteSpace: "normal",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "100%",
                      maxWidth: "90px",
                      maxHeight: 40,
                      verticalAlign: "center",
                      display: "flex",
                      justifyContent: "left",
                    },
                  }}
                />
                <ListItemIcon>
                  {isPopoverOpen ? <ExpandLess /> : <ExpandMore />}{" "}
                </ListItemIcon>
              </ListItem>
              <Popover
                open={isPopoverOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                {user?.businessData?.map((option, index) => (
                  <BusinessCardItem key={index} option={option} />
                ))}
              </Popover>
            </List>
          </CardContent>

          <Divider sx={{ marginLeft: 2, marginRight: 2, marginTop: -1 }} />

          <Button
            fullWidth
            variant="text"
            component={Link}
            href={"/my-business"}
          >
            <Typography
              variant="caption"
              // color={"white"}
              color={theme.palette.mode === "dark" ? "white" : "black"}
              sx={{ textTransform: "none" }}
            >
              Configuraciones
            </Typography>
            <SettingsIcon sx={{ width: 18, height: 18, marginLeft: 1 }} />
          </Button>

          <Divider sx={{ marginLeft: 2, marginRight: 2 }} />
          <Button
            fullWidth
            variant="contained"
            component={Link}
            href={"/on-boarding"}
          >
            <Typography
              variant="caption"
              color={"white"}
              sx={{ textTransform: "none" }}
            >
              Nuevo negocio
            </Typography>
            <AddIcon sx={{ width: 18, height: 18, marginLeft: 1 }} />
          </Button>
        </CardStyle>
      ) : (
        <CardStyle variant="outlined">
          <CardContent
            sx={{ p: 1, cursor: "pointer", "&:last-child": { pb: 1 } }}
          >
            <List sx={{ p: 0, m: 0 }}>
              <ListItem
                alignItems="flex-start"
                component={Link}
                href={"/on-boarding"}
                disableGutters
                sx={{ p: 0, alignItems: "center" }}
              >
                <ListItemAvatar sx={{ mt: 0, ml: 0 }}>
                  <Lottie
                    loop
                    animationData={lottieJson}
                    play
                    style={{ width: 55, height: 55 }}
                  />
                </ListItemAvatar>
                <ListItemText
                  // sx={{ mt: 0 }}
                  primaryTypographyProps={{
                    variant: "body1",
                    style: {
                      textDecoration: "none",
                      fontWeight: "bold",
                      textOverflow: "ellipsis",
                      whiteSpace: "normal",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    },
                  }}
                  primary="Nuevo negocio"
                />
                <ListItemIcon sx={{ mt: 0 }}>
                  <AddIcon />
                </ListItemIcon>
              </ListItem>
            </List>
          </CardContent>
        </CardStyle>
      )}

      {/* <CardStyleSecondary>
        <CardContent sx={{ p: 2 }}>
          <List sx={{ p: 0, m: 0 }}>
            <ListItem alignItems="flex-start" disableGutters sx={{ p: 0 }}>
              <ListItemAvatar sx={{ mt: 0 }}>
                <Avatar
                  variant="rounded"
                  sx={{
                    ...theme.typography.commonAvatar,
                    ...theme.typography.largeAvatar,
                    color: theme.palette.primary.main,
                    border:
                      theme.palette.mode === "dark" ? "1px solid" : "none",
                    borderColor: theme.palette.primary.main,
                    background:
                      theme.palette.mode === "dark"
                        ? theme.palette.dark.dark
                        : "#fff",
                    marginRight: "12px",
                  }}
                >
                  <SavingsIcon fontSize="inherit" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                sx={{ mt: 0 }}
                primary={
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color:
                        theme.palette.mode === "dark"
                          ? theme.palette.dark.light
                          : theme.palette.primary[800],
                    }}
                  >
                    Refiere y gana
                  </Typography>
                }
                secondary={
                  <Typography variant="caption"> $0.00/$350.00</Typography>
                }
              />
            </ListItem>
          </List>
          <LinearProgressWithLabel value={0} />
        </CardContent>
      </CardStyleSecondary> */}
    </div>
  );
};

export default memo(MenuCard);
