// /**
//  * axios setup to use mock service
//  */

// import axios from 'axios';

// const axiosServices = axios.create({ withCredentials: true });
// // export const Axios = axios.create({ withCredentials: true });

// // interceptor for http
// // axiosServices.interceptors.response.use(
// //     (response) => response,
// //     (error) => Promise.reject((error.response && error.response.data) || error)
// // );

// axiosServices.interceptors.request.use(
//   (config) => {
//     console.log('Solicitando:', config.url);
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// axiosServices.interceptors.response.use(
//   (response) => response,
//   (error) => {

//       // Registrar en el log si la respuesta es un error 404
//       if (error.response && error.response.status === 404) {
//           console.error('Recibida respuesta error:', error.response);
//       }

//       return Promise.reject((error.response && error.response.data) || error);
//   }
// );

// axiosServices.interceptors.request.use(function (config) {
//     // console.log(config)
//     return config;
//   }, function (error) {
//     // console.log("error en request",error);
//     // Do something with request error
//     return Promise.reject(error);
//   });

// export default axiosServices;

// // // CODIGO ORIGINAL
// // import axios from "axios";
// // // import { response } from "express";
// // // const axiosServices = axios.create();
// // const axiosServices = axios.create({ withCredentials: true });
// // // ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //
// // axiosServices.interceptors.response.use(
// //   (response) => response,
// //   // (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services')
// //   (error) => {
// //     // Registrar en el log si la respuesta es un error 404
// //     // console.log(response);
// //     console.log("ERROR EN LA API",error.response.status);
// //     // window.location.href = "/login";
// //     // if (error.response && error.response.status === 404) {
// //     //   console.error("Recibida respuesta 403:", error.response);
// //     // }
// //   }
// // );
// // export default axiosServices;
// // // ==============================|| AXIOS - FOR REAL REQUEST ||============================== //
// // // export const Axios = axios.create({ withCredentials: true });

/**
 * axios setup to use mock service
 */

// import axios from 'axios';
// import config from '../config';

// const axiosServices = axios.create({baseURL:config.API_URL});
// axiosServices.interceptors.response.use (response => response.data);
// // Interceptor for http
// axiosServices.interceptors.response.use(
//     (response) => response.data,
//     (error) => {
//       console.log("ERRIR", error);
//       console.log("Error URL:", error.config.url);
//       console.log("Error request method:", error.config.method);
//       console.log("Error request headers:", error.config.headers);
//       console.log("Error request data:", error.config.data);
//         if (error.response && error.response.status >= 400 && error.response.status < 500) {
//             // Redireccionar a la página de inicio de sesión u otra página
//             console.log("REDIRECTING TO LOGIN");

//             // window.location.replace("/login"); // Puedes cambiar "/login" por la URL que desees
//         }
//         return Promise.reject(error);
//     }
// );

// export default axiosServices;

import axios from "axios";
import config from "../config";

const client = axios.create({
  baseURL: config.API_URL,
});
client.interceptors.response.use(
  (response) => {
    console.log(response);
    return response.data;
  },
  (error) => {
    console.log("Error URL:", error.config.url);
  }
);

export default client;
